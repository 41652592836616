import { useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'

import { motion } from 'framer-motion'

import { FlexJustifyBetween, VerticalFlex } from 'components/Styles'

import Logo from 'assets/img/common/Logo.png'
import IconID from 'assets/img/login/ico_id.png'
import IconPwd from 'assets/img/login/ico_psw.png'
import { ConfirmAlertTypes, UserLoginTypes, UserStateTypes } from 'types'
import { useSelector, useDispatch } from 'react-redux'
import { selectuserInfo } from 'hooks/userInfo'
import { getCurrentUser, setCurrentUser } from 'helpers/storage'
import { setUserInfo } from 'hooks/userInfo'

import ConfrimAlert from 'components/ConfirmAlert'

const Wrapper = styled(VerticalFlex)`
	align-items: center;
	justify-content: center;
	.inner {
		width: 400px;
	}
	background: rgb(255, 200, 170);
	background: linear-gradient(
		0deg,
		rgba(255, 200, 170, 1) 0%,
		rgba(242, 184, 184, 1) 100%
	);
`
const Forgots = styled(FlexJustifyBetween)`
	margin-top: 20px;
	margin-bottom: 20px;
	a {
		font-size: 25px;
	}
`
const LogoImg = styled.div`
	margin-bottom: 70px;
	@media (max-width: 767px) {
		img {
			max-width: 300px;
		}
	}
`
const ButtonContainer = styled.div`
	width: 100%;
	padding-left: 35px;
	padding-right: 35px;
	margin-left: auto;
	margin-right: auto;
`
const Button = styled.button`
	line-height: 73px;
	display: block;
	color: #fff;
	background-color: #606060;
	text-align: center;
	border-radius: 50px;
	width: 100%;
	font-size: 50px;
	&.login {
		background-color: #e86262;
		margin-bottom: 15px;
	}
`
const Input = styled.div`
	position: relative;
	label {
		position: absolute;
		top: 10px;
		left: 20px;
		width: 65px;
		height: 40px;
		overflow: hidden;
	}
	input {
		padding-left: 100px;
		height: 60px;
		background-color: #fff;
		color: #ce7c8f;
		font-size: 30px;
		width: 100%;
		border-radius: 50px;
		&.id {
			margin-bottom: 25px;
		}
	}
`

const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [userInputs, setUserInputs] = useState<UserStateTypes>({
		email: '',
		userId: '',
		nickName: '',
		roles: [],
	})
	const setUserState = ''
	const userStateValue = ''

	const cpUserInfo = useSelector(selectuserInfo)
	const cpUser = getCurrentUser()

	const [popupIsOpen, setPopupIsOpen] = useState(false)
	const [popupMessageObject, setPopupMessageObject] =
		useState<ConfirmAlertTypes>({
			title: 'Loren ipsum',
			content:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse in est ante in nibh mauris cursus mattis molestie. Ante in nibh mauris cursus mattis molestie a iaculis. Viverra mauris in aliquam sem fringilla ut morbi tincidunt. Praesent semper feugiat nibh sed pulvinar. Sed arcu non odio euismod lacinia. Tincidunt ornare massa eget egestas purus viverra accumsan in. Pharetra vel turpis nunc eget lorem dolor sed viverra. Id venenatis a condimentum vitae sapien pellentesque habitant morbi tristique. Lacus sed turpis tincidunt id. Consectetur a erat nam at lectus urna. Rhoncus mattis rhoncus urna neque viverra. Tristique sollicitudin nibh sit amet commodo. Dui accumsan sit amet nulla facilisi morbi tempus iaculis. Sodales ut etiam sit amet nisl purus in mollis nunc. Nunc consequat interdum varius sit amet mattis vulputate enim',
		})

	const onPopupAccept = () => {
		setPopupIsOpen(false)
		setPopupMessageObject({
			title: '',
			content: '',
		})
	}

	const onPopupCancel = () => {
		setPopupIsOpen(false)
		setPopupMessageObject({
			title: '',
			content: '',
		})
	}

	const [loginForm, setLoginForm] = useState<UserLoginTypes>({
		userId: '',
		password: '',
	})

	const onKeyUp = (e: any) => {
		e.stopPropagation()
		if (e.keyCode === 13) {
			loginAction()
		}
	}

	const loginAction = () => {
		console.log(loginForm)
		if (loginForm.userId?.trim() === '') {
			setPopupMessageObject({
				title: '로그인 실패',
				content: '아이디를 입력해주세요.',
			})
			setPopupIsOpen(true)
			return
		}
		if (loginForm.password?.trim() === '') {
			setPopupMessageObject({
				title: '로그인 실패',
				content: '비밀번호를 입력해주세요.',
			})
			setPopupIsOpen(true)
			return
		}
		if (loginForm.userId === 'admin' && loginForm.password === 'admin') {
			const data = {
				userId: 'admin',
				email: '',
				nickName: '닉네임',
				roles: [
					{
						roleId: 3,
						roleName: 'ROLE_ADMIN',
					},
					{
						roleId: 1,
						roleName: 'ROLE_USER',
					},
					{
						roleId: 2,
						roleName: 'ROLE_SYSTEM',
					},
				],
			}
			dispatch(setUserInfo(data))
			const authInfo = {
				accessToken: 'admin',
				refreshToken: 'admin',
				tokenType: 'admin',
				expiryDuration: 100000,
			}
			setCurrentUser(authInfo)
			navigate('/game/area')
		} else {
			setPopupMessageObject({
				title: '로그인 실패',
				content: '아이디 또는 비밀번호를 확인해주세요.',
			})
			setPopupIsOpen(true)
		}
	}

	const onLoginClick = () => {
		loginAction()
	}

	return (
		<>
			<ConfrimAlert
				popupIsOpen={popupIsOpen}
				popupMessageObject={popupMessageObject}
				onPopupAccept={onPopupAccept}
				onPopupCancel={onPopupCancel}
			/>
			<Wrapper>
				<div className="inner">
					<form
						autoComplete="off"
						onKeyUp={onKeyUp}
					>
						<motion.div
							animate={{ y: [0, -10, 0] }}
							transition={{
								duration: 2,
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatDelay: 0.5,
							}}
						>
							<LogoImg>
								<img
									src={Logo}
									alt="logo"
								/>
							</LogoImg>
						</motion.div>
						<motion.div animate={{ opacity: [0, 1] }}>
							<Input>
								<label htmlFor="id">
									<img
										src={IconID}
										alt="ID"
									/>
								</label>
								<input
									type="text"
									placeholder="ID"
									className="id"
									id="id"
									value={loginForm.userId}
									onChange={(e) => {
										setLoginForm({
											...loginForm,
											userId: e.target.value,
										})
									}}
								/>
							</Input>
							<Input>
								<label htmlFor="password">
									<img
										src={IconPwd}
										alt="PASSWORD"
									/>
								</label>
								<input
									type="password"
									placeholder="PASSWORD"
									id="password"
									value={loginForm.password}
									onChange={(e) => {
										setLoginForm({
											...loginForm,
											password: e.target.value,
										})
									}}
								/>
							</Input>
						</motion.div>
						<motion.div
							animate={{ opacity: [0, 1] }}
							transition={{ delay: 0.3 }}
						>
							<Forgots>
								<Link to="/">forgot your ID?</Link>
								<Link to="/">forgot your password?</Link>
							</Forgots>
						</motion.div>
						<ButtonContainer>
							<motion.div
								animate={{ scale: [0, 1] }}
								transition={{ delay: 0.5 }}
							>
								<Button
									type="button"
									className="login"
									onClick={onLoginClick}
								>
									LOGIN
								</Button>
							</motion.div>
							<motion.div
								animate={{ scale: [0, 1] }}
								transition={{ delay: 0.7 }}
							>
								<Button
									type="button"
									onClick={() => {
										navigate('/signup')
									}}
								>
									SIGNUP
								</Button>
							</motion.div>
						</ButtonContainer>
					</form>
				</div>
			</Wrapper>
		</>
	)
}

export default Login
