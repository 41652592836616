import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate, Link, useLocation } from 'react-router-dom'

import { motion } from 'framer-motion'
import Typewriter from 'typewriter-effect'

import CsBg from 'assets/img/stage/csbg.png'
import CharacterImg from 'assets/img/stage/character.png'

import BtnHome from 'assets/img/common/btn_home.png'
import BtnBack from 'assets/img/common/btn_back.png'
import {
	CenterBlock,
	PhotoPoint,
	PhotoPointPopupContainer,
	PhotoPointPopupWrapper,
} from 'components/Styles'
import PhotoPrg from 'assets/img/common/photo_prg.svg'

import PhotoPrgImg from 'assets/img/common/photo_prg.png'
import PhotoDoneImg from 'assets/img/common/photo_done.png'

import Man01 from 'assets/img/Man_01.png'

import C004_1_1 from 'assets/img/c_004_1/001.jpg'
import C004_1_6 from 'assets/img/c_004_1/006.jpg'
import C004_1_7 from 'assets/img/c_004_1/007.jpg'
import C004_1_8 from 'assets/img/c_004_1/008.jpg'
import C004_1_9 from 'assets/img/c_004_1/009.jpg'
import C004_1_10 from 'assets/img/c_004_1/010.jpg'
import C004_1_11 from 'assets/img/c_004_1/011.jpg'
import C004_1_12 from 'assets/img/c_004_1/012.jpg'
import C004_1_13 from 'assets/img/c_004_1/013.jpg'
import C004_1_14 from 'assets/img/c_004_1/014.jpg'
import C004_1_15 from 'assets/img/c_004_1/015.jpg'
import C004_1_16 from 'assets/img/c_004_1/016.jpg'
import C004_1_17 from 'assets/img/c_004_1/017.jpg'
import C004_1_18 from 'assets/img/c_004_1/018.jpg'
import C004_1_19 from 'assets/img/c_004_1/019.jpg'
import C004_1_20 from 'assets/img/c_004_1/020.jpg'
import C004_1_21 from 'assets/img/c_004_1/021.jpg'
import C004_1_22 from 'assets/img/c_004_1/022.jpg'
import C004_1_23 from 'assets/img/c_004_1/023.jpg'
import C004_1_24 from 'assets/img/c_004_1/024.jpg'
import C004_1_25 from 'assets/img/c_004_1/025.jpg'
import C004_1_26 from 'assets/img/c_004_1/026.jpg'
import C004_1_27 from 'assets/img/c_004_1/027.jpg'
import C004_1_28 from 'assets/img/c_004_1/028.jpg'
import C004_1_29 from 'assets/img/c_004_1/029.jpg'
import C004_1_30 from 'assets/img/c_004_1/030.jpg'
import C004_1_31 from 'assets/img/c_004_1/031.jpg'
import C004_1_32 from 'assets/img/c_004_1/032.jpg'
import C004_1_33 from 'assets/img/c_004_1/033.jpg'
import C004_1_34 from 'assets/img/c_004_1/034.jpg'
import C004_1_35 from 'assets/img/c_004_1/035.jpg'
import C004_1_36 from 'assets/img/c_004_1/036.jpg'
import C004_1_37 from 'assets/img/c_004_1/037.jpg'
import C004_1_38 from 'assets/img/c_004_1/038.jpg'
import C004_1_39 from 'assets/img/c_004_1/039.jpg'
import C004_1_40 from 'assets/img/c_004_1/040.jpg'
import C004_1_002 from 'assets/img/c_004_1/002.png'
import C004_1_003 from 'assets/img/c_004_1/003.png'
import C004_1_004 from 'assets/img/c_004_1/004.png'
import C004_1_005 from 'assets/img/c_004_1/005.png'

import OfficeBg from 'assets/img/Office_01.png'

import C004_2_00002 from 'assets/img/c_004_2/00002-2197190771.jpg'
import C004_2_00004 from 'assets/img/c_004_2/00004-1537752303.jpg'
import C004_2_00047 from 'assets/img/c_004_2/00047-1325231665.jpg'
import C004_2_00056 from 'assets/img/c_004_2/00056-3883599946.jpg'
import C004_2_00087 from 'assets/img/c_004_2/00087-2263031328.jpg'
import C004_2_00100 from 'assets/img/c_004_2/00100-3075680494.jpg'
import C004_2_00131 from 'assets/img/c_004_2/00131-1451024128.jpg'
import C004_2_00133 from 'assets/img/c_004_2/00133-1972802948.jpg'
import C004_2_00176 from 'assets/img/c_004_2/00176-1236511341.jpg'
import C004_2_00187 from 'assets/img/c_004_2/00187-3508329142.jpg'
import C004_2_00219 from 'assets/img/c_004_2/00219-3922498723.jpg'
import C004_2_00255 from 'assets/img/c_004_2/00255-354596276.jpg'
import C004_2_01298 from 'assets/img/c_004_2/01298-2168050715.jpg'
import C004_2_01335 from 'assets/img/c_004_2/01335-3551904536.jpg'
import C004_2_01347 from 'assets/img/c_004_2/01347-532362619.jpg'
import C004_2_01357 from 'assets/img/c_004_2/01357-3391710858.jpg'
import C004_2_01377 from 'assets/img/c_004_2/01377-1658582965.jpg'
import C004_2_01436 from 'assets/img/c_004_2/01436-1657194078.jpg'
import C004_2_01437 from 'assets/img/c_004_2/01437-3236286828.jpg'
import C004_2_01439 from 'assets/img/c_004_2/01439-3827075467.jpg'
import C004_2_01440 from 'assets/img/c_004_2/01440-1242471258.jpg'
import C004_2_01453 from 'assets/img/c_004_2/01453-2015324020.jpg'
import C004_2_01460 from 'assets/img/c_004_2/01460-2466134940.jpg'
import C004_2_01469 from 'assets/img/c_004_2/01469-563170128.jpg'
import C004_2_01537 from 'assets/img/c_004_2/01537-354009087.jpg'
import C004_2_01454 from 'assets/img/c_004_2/01543-1634789293.jpg'
import C004_2_01546 from 'assets/img/c_004_2/01546-3685089472.jpg'
import C004_2_01553 from 'assets/img/c_004_2/01553-1442368833.jpg'
import C004_2_01608 from 'assets/img/c_004_2/01608-3127432781.jpg'
import C004_2_01620 from 'assets/img/c_004_2/01620-3925071754.jpg'
import C004_2_01679 from 'assets/img/c_004_2/01679-4179024673.jpg'
import C004_2_01681 from 'assets/img/c_004_2/01681-2255974804.jpg'
import C004_2_01693 from 'assets/img/c_004_2/01693-2086108176.jpg'
import C004_2_01694 from 'assets/img/c_004_2/01694-3295501604.jpg'
import C004_2_01698 from 'assets/img/c_004_2/01698-492573283.jpg'
import C004_2_01701 from 'assets/img/c_004_2/01701-320457731.jpg'
import C004_2_01707 from 'assets/img/c_004_2/01707-970544184.jpg'
import C004_2_01714 from 'assets/img/c_004_2/01714-3568741269.jpg'
import C004_2_01718 from 'assets/img/c_004_2/01718-3706565340.jpg'
import C004_2_01726 from 'assets/img/c_004_2/01726-1364149067.jpg'

import C004_3_00202 from 'assets/img/c_004_3/00202-1825934805.jpg'
import C004_3_00230 from 'assets/img/c_004_3/00230-1127100601.jpg'
import C004_3_00237 from 'assets/img/c_004_3/00237-3008388085.jpg'
import C004_3_00238 from 'assets/img/c_004_3/00238-3077875502.jpg'
import C004_3_00243 from 'assets/img/c_004_3/00243-3728775040.jpg'
import C004_3_00275 from 'assets/img/c_004_3/00275-3633210733.jpg'
import C004_3_00280 from 'assets/img/c_004_3/00280-3786299416.jpg'
import C004_3_00282 from 'assets/img/c_004_3/00282-990658370.jpg'
import C004_3_00293 from 'assets/img/c_004_3/00293-3174886359.jpg'
import C004_3_00294 from 'assets/img/c_004_3/00294-171419357.jpg'
import C004_3_00295 from 'assets/img/c_004_3/00294-941082231.jpg'
import C004_3_00296 from 'assets/img/c_004_3/00296-188623600.jpg'
import C004_3_00297 from 'assets/img/c_004_3/00297-1887168606.jpg'
import C004_3_00309 from 'assets/img/c_004_3/00309-1154860511.jpg'
import C004_3_00311 from 'assets/img/c_004_3/00311-2915616288.jpg'
import C004_3_00324 from 'assets/img/c_004_3/00324-3409320919.jpg'
import C004_3_00346 from 'assets/img/c_004_3/00346-751836707.jpg'
import C004_3_00376 from 'assets/img/c_004_3/00376-3912900828.jpg'
import C004_3_00424 from 'assets/img/c_004_3/00424-1088001058.jpg'
import C004_3_00438 from 'assets/img/c_004_3/00438-1582958315.jpg'
import C004_3_00453 from 'assets/img/c_004_3/00453-1881011710.jpg'
import C004_3_00455 from 'assets/img/c_004_3/00455-3958386227.jpg'
import C004_3_00465 from 'assets/img/c_004_3/00465-2863810505.jpg'
import C004_3_00486 from 'assets/img/c_004_3/00486-2262250661.jpg'
import C004_3_00524 from 'assets/img/c_004_3/00524-3257669284.jpg'
import C004_3_00526 from 'assets/img/c_004_3/00526-894904705.jpg'
import C004_3_00585 from 'assets/img/c_004_3/00585-1987688111.jpg'
import C004_3_00589 from 'assets/img/c_004_3/00589-810449604.jpg'
import C004_3_00624 from 'assets/img/c_004_3/00624-4247353033.jpg'
import C004_3_00626 from 'assets/img/c_004_3/00626-1792202453.jpg'
import C004_3_00647 from 'assets/img/c_004_3/00647-2328846736.jpg'
import C004_3_00649 from 'assets/img/c_004_3/00649-2285507959.jpg'
import C004_3_00689 from 'assets/img/c_004_3/00689-3693608125.jpg'
import C004_3_00806 from 'assets/img/c_004_3/00806-751094432.jpg'
import C004_3_00816 from 'assets/img/c_004_3/00816-4019825570.jpg'
import C004_3_00835 from 'assets/img/c_004_3/00835-2731166829.jpg'
import C004_3_00836 from 'assets/img/c_004_3/00836-4283221052.jpg'
import C004_3_00849 from 'assets/img/c_004_3/00849-607497053.jpg'
import C004_3_00857 from 'assets/img/c_004_3/00857-3512236150.jpg'
import C004_3_00859 from 'assets/img/c_004_3/00859-2020944408.jpg'

import C004_4_00001 from 'assets/img/c_004_4/00001-537964940.jpg'
import C004_4_00017 from 'assets/img/c_004_4/00017-3467508294.jpg'
import C004_4_00019 from 'assets/img/c_004_4/00019-661203288.jpg'
import C004_4_00022 from 'assets/img/c_004_4/00022-2491994344.jpg'
import C004_4_00032 from 'assets/img/c_004_4/00032-4136065707.jpg'
import C004_4_00043 from 'assets/img/c_004_4/00043-649681453.jpg'
import C004_4_00044 from 'assets/img/c_004_4/00044-4178512080.jpg'
import C004_4_00045 from 'assets/img/c_004_4/00045-1557384619.jpg'
import C004_4_00047 from 'assets/img/c_004_4/00047-283397710.jpg'
import C004_4_00049 from 'assets/img/c_004_4/00049-1409103547.jpg'
import C004_4_00057 from 'assets/img/c_004_4/00057-3675999420.jpg'
import C004_4_00063 from 'assets/img/c_004_4/00063-2165093575.jpg'
import C004_4_00067 from 'assets/img/c_004_4/00067-1111998731.jpg'
import C004_4_00077 from 'assets/img/c_004_4/00077-3313116439.jpg'
import C004_4_00078 from 'assets/img/c_004_4/00078-3636335410.jpg'
import C004_4_00081 from 'assets/img/c_004_4/00081-2676363120.jpg'
import C004_4_00082 from 'assets/img/c_004_4/00082-3166630020.jpg'
import C004_4_00090 from 'assets/img/c_004_4/00090-2963313353.jpg'
import C004_4_00106 from 'assets/img/c_004_4/00106-1367074340.jpg'
import C004_4_00107 from 'assets/img/c_004_4/00107-954072962.jpg'
import C004_4_00114 from 'assets/img/c_004_4/00114-342715089.jpg'
import C004_4_00118 from 'assets/img/c_004_4/00118-955096974.jpg'
import C004_4_00129 from 'assets/img/c_004_4/00129-899725343.jpg'
import C004_4_00141 from 'assets/img/c_004_4/00141-2537277623.jpg'
import C004_4_00143 from 'assets/img/c_004_4/00143-3380599485.jpg'
import C004_4_00144 from 'assets/img/c_004_4/00144-3272568802.jpg'
import C004_4_00146 from 'assets/img/c_004_4/00146-2684857388.jpg'
import C004_4_00148 from 'assets/img/c_004_4/00148-3416460301.jpg'
import C004_4_00151 from 'assets/img/c_004_4/00151-1058768188.jpg'
import C004_4_00152 from 'assets/img/c_004_4/00152-1024205889.jpg'
import C004_4_00156 from 'assets/img/c_004_4/00155-420389850.jpg'
import C004_4_00158 from 'assets/img/c_004_4/00185-2067381004.jpg'
import C004_4_00226 from 'assets/img/c_004_4/00226-2777331630.jpg'
import C004_4_00228 from 'assets/img/c_004_4/00228-608834179.jpg'
import C004_4_00232 from 'assets/img/c_004_4/00232-1129471412.jpg'
import C004_4_00240 from 'assets/img/c_004_4/00240-1408843060.jpg'
import C004_4_00270 from 'assets/img/c_004_4/00270-3425481404.jpg'
import C004_4_00422 from 'assets/img/c_004_4/00422-296903377.jpg'
import C004_4_00424 from 'assets/img/c_004_4/00424-2797260776.jpg'
import C004_4_00550 from 'assets/img/c_004_4/00550-2361857839.jpg'

import C004_5_00004 from 'assets/img/c_004_5/00004-2671292621.jpg'
import C004_5_00005 from 'assets/img/c_004_5/00005-3923314481.jpg'
import C004_5_00009 from 'assets/img/c_004_5/00009-1209719090.jpg'
import C004_5_00010 from 'assets/img/c_004_5/00010-2114455194.jpg'
import C004_5_00011 from 'assets/img/c_004_5/00011-2606879149.jpg'
import C004_5_00012 from 'assets/img/c_004_5/00012-3961255288.jpg'
import C004_5_00018 from 'assets/img/c_004_5/00018-2963934108.jpg'
import C004_5_00029 from 'assets/img/c_004_5/00029-494385576.jpg'
import C004_5_00038 from 'assets/img/c_004_5/00038-1754814790.jpg'
import C004_5_00039 from 'assets/img/c_004_5/00039-3853420217.jpg'
import C004_5_00041 from 'assets/img/c_004_5/00041-3289572018.jpg'
import C004_5_00044 from 'assets/img/c_004_5/00044-704257774.jpg'
import C004_5_00050 from 'assets/img/c_004_5/00050-3064179783.jpg'
import C004_5_00064 from 'assets/img/c_004_5/00063-4090431285.jpg'
import C004_5_00065 from 'assets/img/c_004_5/00065-3775918705.jpg'
import C004_5_00067 from 'assets/img/c_004_5/00067-3700532372.jpg'
import C004_5_00074 from 'assets/img/c_004_5/00074-3918161415.jpg'
import C004_5_00076 from 'assets/img/c_004_5/00076-3298513140.jpg'
import C004_5_00096 from 'assets/img/c_004_5/00096-2136408881.jpg'
import C004_5_00097 from 'assets/img/c_004_5/00097-1658636894.jpg'
import C004_5_00105 from 'assets/img/c_004_5/00105-1277587418.jpg'
import C004_5_00118 from 'assets/img/c_004_5/00118-685264400.jpg'
import C004_5_00119 from 'assets/img/c_004_5/00119-2086359517.jpg'
import C004_5_00122 from 'assets/img/c_004_5/00122-220488536.jpg'
import C004_5_00147 from 'assets/img/c_004_5/00147-3479447844.jpg'
import C004_5_00150 from 'assets/img/c_004_5/00150-3398927449.jpg'
import C004_5_00154 from 'assets/img/c_004_5/00154-2507792459.jpg'
import C004_5_00160 from 'assets/img/c_004_5/00160-3815484216.jpg'
import C004_5_00163 from 'assets/img/c_004_5/00163-2037067370.jpg'
import C004_5_00183 from 'assets/img/c_004_5/00183-1274425998.jpg'
import C004_5_00236 from 'assets/img/c_004_5/00236-4183224383.jpg'
import C004_5_00243 from 'assets/img/c_004_5/00243-121975867.jpg'
import C004_5_00247 from 'assets/img/c_004_5/00247-3261474754.jpg'
import C004_5_00268 from 'assets/img/c_004_5/00268-2660179977.jpg'
import C004_5_00273 from 'assets/img/c_004_5/00273-2469423582.jpg'
import C004_5_00274 from 'assets/img/c_004_5/00274-1314501455.jpg'
import C004_5_00280 from 'assets/img/c_004_5/00280-3952178811.jpg'
import C004_5_00298 from 'assets/img/c_004_5/00298-100706588.jpg'
import C004_5_00343 from 'assets/img/c_004_5/00343-2707663601.jpg'
import C004_5_00393 from 'assets/img/c_004_5/00393-4053089423.jpg'

import C004_6_00008 from 'assets/img/c_004_6/00008-2435183420.png'
import C004_6_00012 from 'assets/img/c_004_6/00012-329620767.png'
import C004_6_00014 from 'assets/img/c_004_6/00014-1701514438.png'
import C004_6_00015 from 'assets/img/c_004_6/00015-1441881166.png'
import C004_6_00017 from 'assets/img/c_004_6/00017-2226848903.png'
import C004_6_00018 from 'assets/img/c_004_6/00018-2689200729.png'
import C004_6_00019 from 'assets/img/c_004_6/00019-1332044328.png'
import C004_6_00020 from 'assets/img/c_004_6/00020-3212322702.png'
import C004_6_00021 from 'assets/img/c_004_6/00021-958305266.png'
import C004_6_00022 from 'assets/img/c_004_6/00022-1557509789.png'
import C004_6_00023 from 'assets/img/c_004_6/00023-1038913245.png'
import C004_6_00024 from 'assets/img/c_004_6/00024-342960755.png'
import C004_6_00025 from 'assets/img/c_004_6/00025-4213380765.png'
import C004_6_00026 from 'assets/img/c_004_6/00026-1795229213.png'
import C004_6_00027 from 'assets/img/c_004_6/00027-1726491145.png'
import C004_6_00028 from 'assets/img/c_004_6/00028-1809186940.png'
import C004_6_00030 from 'assets/img/c_004_6/00030-4203679492.png'
import C004_6_00032 from 'assets/img/c_004_6/00032-3009983827.png'
import C004_6_00034 from 'assets/img/c_004_6/00034-3825640304.png'
import C004_6_00035 from 'assets/img/c_004_6/00035-1003997800.png'
import C004_6_00037 from 'assets/img/c_004_6/00037-3178811240.png'
import C004_6_00038 from 'assets/img/c_004_6/00038-2118967698.png'
import C004_6_00039 from 'assets/img/c_004_6/00039-1012045932.png'
import C004_6_00042 from 'assets/img/c_004_6/00042-1468911509.png'
import C004_6_00043 from 'assets/img/c_004_6/00043-3467864213.png'
import C004_6_00044 from 'assets/img/c_004_6/00044-577111489.png'
import C004_6_00047 from 'assets/img/c_004_6/00047-1835999249.png'
import C004_6_00048 from 'assets/img/c_004_6/00048-2327645281.png'
import C004_6_00049 from 'assets/img/c_004_6/00049-3343288350.png'
import C004_6_00050 from 'assets/img/c_004_6/00050-1553267465.png'
import C004_6_00051 from 'assets/img/c_004_6/00051-169325770.png'
import C004_6_00052 from 'assets/img/c_004_6/00052-2316814300.png'
import C004_6_00053 from 'assets/img/c_004_6/00053-351362818.png'
import C004_6_00054 from 'assets/img/c_004_6/00054-1563029970.png'
import C004_6_00055 from 'assets/img/c_004_6/00055-3539698855.png'
import C004_6_00056 from 'assets/img/c_004_6/00056-333499909.png'
import C004_6_00057 from 'assets/img/c_004_6/00057-1048246345.png'

const c0041List = [
	C004_1_1,
	C004_1_6,
	C004_1_7,
	C004_1_8,
	C004_1_9,
	C004_1_10,
	C004_1_11,
	C004_1_12,
	C004_1_13,
	C004_1_14,
	C004_1_15,
	C004_1_16,
	C004_1_17,
	C004_1_18,
	C004_1_19,
	C004_1_20,
	C004_1_21,
	C004_1_22,
	C004_1_23,
	C004_1_24,
	C004_1_25,
	C004_1_26,
	C004_1_27,
	C004_1_28,
	C004_1_29,
	C004_1_30,
	C004_1_31,
	C004_1_32,
	C004_1_33,
	C004_1_34,
	C004_1_35,
	C004_1_36,
	C004_1_37,
	C004_1_38,
	C004_1_39,
	C004_1_40,
	C004_1_002,
	C004_1_003,
	C004_1_004,
	C004_1_005,
]

const c0042List = [
	C004_2_00002,
	C004_2_00004,
	C004_2_00047,
	C004_2_00056,
	C004_2_00087,
	C004_2_00100,
	C004_2_00131,
	C004_2_00133,
	C004_2_00176,
	C004_2_00187,
	C004_2_00219,
	C004_2_00255,
	C004_2_01298,
	C004_2_01335,
	C004_2_01347,
	C004_2_01357,
	C004_2_01377,
	C004_2_01436,
	C004_2_01437,
	C004_2_01439,
	C004_2_01440,
	C004_2_01453,
	C004_2_01460,
	C004_2_01469,
	C004_2_01537,
	C004_2_01454,
	C004_2_01546,
	C004_2_01553,
	C004_2_01608,
	C004_2_01620,
	C004_2_01679,
	C004_2_01681,
	C004_2_01693,
	C004_2_01694,
	C004_2_01698,
	C004_2_01701,
	C004_2_01707,
	C004_2_01714,
	C004_2_01718,
	C004_2_01726,
]

const c0043List = [
	C004_3_00202,
	C004_3_00230,
	C004_3_00237,
	C004_3_00238,
	C004_3_00243,
	C004_3_00275,
	C004_3_00280,
	C004_3_00282,
	C004_3_00293,
	C004_3_00294,
	C004_3_00295,
	C004_3_00296,
	C004_3_00297,
	C004_3_00309,
	C004_3_00311,
	C004_3_00324,
	C004_3_00346,
	C004_3_00376,
	C004_3_00424,
	C004_3_00438,
	C004_3_00453,
	C004_3_00455,
	C004_3_00465,
	C004_3_00486,
	C004_3_00524,
	C004_3_00526,
	C004_3_00585,
	C004_3_00589,
	C004_3_00624,
	C004_3_00626,
	C004_3_00647,
	C004_3_00649,
	C004_3_00689,
	C004_3_00806,
	C004_3_00816,
	C004_3_00835,
	C004_3_00836,
	C004_3_00849,
	C004_3_00857,
	C004_3_00859,
]

const c0044List = [
	C004_4_00001,
	C004_4_00017,
	C004_4_00019,
	C004_4_00022,
	C004_4_00032,
	C004_4_00043,
	C004_4_00044,
	C004_4_00045,
	C004_4_00047,
	C004_4_00049,
	C004_4_00057,
	C004_4_00063,
	C004_4_00067,
	C004_4_00077,
	C004_4_00078,
	C004_4_00081,
	C004_4_00082,
	C004_4_00090,
	C004_4_00106,
	C004_4_00107,
	C004_4_00114,
	C004_4_00118,
	C004_4_00129,
	C004_4_00141,
	C004_4_00143,
	C004_4_00144,
	C004_4_00146,
	C004_4_00148,
	C004_4_00151,
	C004_4_00152,
	C004_4_00156,
	C004_4_00158,
	C004_4_00226,
	C004_4_00228,
	C004_4_00232,
	C004_4_00240,
	C004_4_00270,
	C004_4_00422,
	C004_4_00424,
	C004_4_00550,
]

const c0045List = [
	C004_5_00004,
	C004_5_00005,
	C004_5_00009,
	C004_5_00010,
	C004_5_00011,
	C004_5_00012,
	C004_5_00018,
	C004_5_00029,
	C004_5_00038,
	C004_5_00039,
	C004_5_00041,
	C004_5_00044,
	C004_5_00050,
	C004_5_00064,
	C004_5_00065,
	C004_5_00067,
	C004_5_00074,
	C004_5_00076,
	C004_5_00096,
	C004_5_00097,
	C004_5_00105,
	C004_5_00118,
	C004_5_00119,
	C004_5_00122,
	C004_5_00147,
	C004_5_00150,
	C004_5_00154,
	C004_5_00160,
	C004_5_00163,
	C004_5_00183,
	C004_5_00236,
	C004_5_00243,
	C004_5_00247,
	C004_5_00268,
	C004_5_00273,
	C004_5_00274,
	C004_5_00280,
	C004_5_00298,
	C004_5_00343,
	C004_5_00393,
]

const c0046List = [
	C004_6_00008,
	C004_6_00012,
	C004_6_00014,
	C004_6_00015,
	C004_6_00017,
	C004_6_00018,
	C004_6_00019,
	C004_6_00020,
	C004_6_00021,
	C004_6_00022,
	C004_6_00023,
	C004_6_00024,
	C004_6_00025,
	C004_6_00026,
	C004_6_00027,
	C004_6_00028,
	C004_6_00030,
	C004_6_00032,
	C004_6_00034,
	C004_6_00035,
	C004_6_00037,
	C004_6_00038,
	C004_6_00039,
	C004_6_00042,
	C004_6_00043,
	C004_6_00044,
	C004_6_00047,
	C004_6_00048,
	C004_6_00049,
	C004_6_00050,
	C004_6_00051,
	C004_6_00052,
	C004_6_00053,
	C004_6_00054,
	C004_6_00055,
	C004_6_00056,
	C004_6_00057,
]

const c0041TextList = [
	'이정도면 됐나요?',
	'오늘은 노출이 좀 적은 얌전한 복장이예요',
	'치마 안은 노팬티예요',
	'스커트를 좋아 하셔서 스커트 입고 왔어요',
	'스타킹 찢어주세요',
	'야한 생각을 하니까 팬티가 젖는 듯 해요.',
	'시선으로 겁탈 당하는 느낌이예요.',
	'엉덩이에 딱 붙는 옷 좋아하세요?',
	'가슴이 커서 옷이 불편해요',
	'너무 야해 보이나요?',
]

const c0042TextList = [
	'부끄러워요.',
	'사람들이 볼까봐 두근두근..',
	'속옷 안은 젖어있어요',
	'회사에서 이러면 안되요..',
	'부끄러워요..',
	'속옷 마음에 드시나요?',
	'그런 음탕한 눈으로 보지 마세요.',
	'벗겨주세요.',
	'전에 사주신 속옷 입고왔어요.',
	'거칠게 찢어 주세요.',
]

const c0043TextList = [
	'어제도 시키셨잖아요..',
	'아.. 점점 젖고있어요',
	'자위만으로 만족을 못하겠어요',
	'누가 보면 어쩌죠?',
	'덮쳐주세요..',
	'손이 점점 깊은 곳으로 가려고 해요.',
	'신음소리가 참는게 힘들어요.',
	'사무실에서 하니까 더 흥분되요.',
	'회의실에 아무도 없어요.. 빨리…',
	'부끄러운데 흥분되요.',
]

const c0044TextList = [
	'점점 흥분되요.',
	'나도모르게 벗고있네요.',
	'아.. 넣어주세요.',
	'빨아주세요..',
	'크고 굵은거 넣어주세요.',
	'자위만으로는 만족을 못하겠어요.',
	'촵촵~~ 찌겅찌겅~~',
	'손가락보다 주인님 껄 넣어주세요.',
	'엉덩이에도 넣고주세요',
	'성노예로 만들어 주세요',
]

const c0045TextList = [
	'빨리 넣어주세요.',
	'아아~~~ 너무 좋아요.',
	'엉덩이 때려주세요.',
	'뒤로 넣어주세요.',
	'빨고싶어요.',
	'아~~아~~',
	'안에다 싸주세요.',
	'입에 싸주세요.',
	'거칠게 박아주세요.',
	'주인님의 암캐.. 아아~~',
]

const c0046TextList = [
	'갈거같아요..',
	'아앙~~~ 으~~',
	'너무 좋아요.. 주인님',
	'주인님의 성노예.. 행복해요.',
	'걸레라고 불러주세요.',
	'주인님 존슨.. 감사합니다.',
	'주인님꺼 너무 크고 맛있어요.',
	'아직 뜨겁고 움찔거려요.',
	'물이 너무 많이 나와서 부끄러워요.',
	'내일도 넣어 주실거죠?',
]

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: url(${OfficeBg}) no-repeat center center;
	background-size: cover;
	overflow: auto;
`

const BtnHomeLink = styled(Link)`
	position: absolute;
	top: 210px;
	right: 80px;
`

const BtnBackLink = styled.button`
	position: absolute;
	top: 210px;
	left: 80px;
`

const BtnContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
`
const BtnPrev = styled.button`
	display: block;
	line-height: 45px;
	border-radius: 50px;
	border: 2px solid #fff;
	color: #fff;
	text-align: center;
	font-size: 50px;
	background: #606060;
	max-width: 230px;
	min-width: 150px;
	margin-right: 25px;
`

const BtnNext = styled.button`
	display: block;
	line-height: 45px;
	border-radius: 50px;
	border: 2px solid #fff;
	color: #fff;
	text-align: center;
	font-size: 50px;
	background: #ef4545;
	max-width: 230px;
	min-width: 150px;
	margin-left: 25px;
`
const ContentContainer = styled.div`
	position: absolute;
	top: 300px;
	left: 80px;
	right: 80px;
	bottom: 80px;
`
const TextContainer = styled.div`
	border-radius: 30px;
	padding: 100px;
	background: rgba(255, 255, 255, 0.8);
	text-align: left;
	font-size: 28px;
	min-height: 450px;
	overflow-y: auto;
	@media (max-width: 1199px) {
		padding: 50px;
		font-size: 25px;
		height: auto;
	}
	.Typewriter {
		display: flex;
		justify-content: center;
	}
`

const ContentContainer2 = styled.div`
	position: absolute;
	bottom: 80px;
	left: 80px;
	right: 80px;
	bottom: 80px;
`
const TextContainer2 = styled.div`
	border-radius: 30px;
	padding: 30px 100px;
	background: rgba(255, 255, 255, 0.8);
	text-align: center;
	font-size: 28px;
	overflow-y: auto;
	.nm {
		font-size: 60px;
	}
	@media (max-width: 1199px) {
		padding: 50px;
		font-size: 25px;
		height: auto;
	}
`

const CharImg = styled.img`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	@media (max-width: 1199px) {
		max-width: 400px;
	}
`

const CommentList = styled.ul`
	position: absolute;
	bottom: 100px;
	left: 50%;
	transform: translateX(-50%);
	width: 450px;
	li {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		button {
			font-size: 40px;
			line-height: 75px;
			color: #fff;
			width: 100%;
			background: #ef4545;
			border-radius: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
`

const LoadingContainer = styled.div`
	position: relative;
	margin: 0 auto;
	.photoPrg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`

const LoadingDots = styled.div`
	display: block;
	width: 242px;
	height: 242px;
	margin: 0 auto;
	// rotate animation
	animation: rotate 2s linear infinite;
	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
`

const PhotoShotContainer = styled.div`
	width: 240px;
	height: 240px;
	background: #fff;
	border-radius: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	font-size: 80px;
	position: relative;
	.arr {
		position: absolute;
		right: -50px;
		display: block;
		width: 0px;
		height: 0px;
		padding: 0;
		&.up {
			top: 70px;
			border-right: 15px solid transparent;
			border-left: 15px solid transparent;
			border-bottom: 30px solid #000;
			text-indent: -9999px;
	}
`
const ArrDown = styled.button`
	position: absolute;
	right: -50px;
	display: block;
	width: 0px;
	height: 0px;
	padding: 0;
	bottom: 70px;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-top: 30px solid #000;
	text-indent: -9999px;
`

const AreaDetail = () => {
	const navigate = useNavigate()
	const [stepNumber, setStepNumber] = useState(0)
	const [selectedPhotoTextList, setSelectedPhotoTextList] = useState<any[]>([])
	const [selectedText, setSelectedText] = useState('')

	const [poseNumber, setPoseNumber] = useState('')

	const [photoCount, setPhotoCount] = useState(1)
	const [point, setPoint] = useState(100)

	const handlePhotoCount = (dir: string) => {
		if (dir === 'up') {
			if (photoCount >= 10) {
				return
			}
			setPhotoCount(photoCount + 1)
			setPoint((photoCount + 1) * 100)
		} else {
			if (photoCount <= 1) {
				return
			}
			setPhotoCount(photoCount - 1)
			setPoint((photoCount - 1) * 100)
		}
	}

	const handlePhotoClick = (text: string) => {
		console.log(text)
		// if text is 1 then get random photo from c0041List or 2 then get random photo from c0042List or 3 then get random photo from c0043List or 4 then get random photo from c0044List or 5 then get random photo from c0045List or 6 then get random photo from c0046List
		if (text) {
			setSelectedPhotoTextList([])
			setSelectedText('')
			setStepNumber(4)
			setPoseNumber(text)
		} else {
			setSelectedPhotoTextList([])
			setSelectedText('')
			setStepNumber(3)
			setPoseNumber(text)
		}
	}

	const takeShot = () => {
		console.log(photoCount)
		// photoCount 갯수 만큼 setSelectedPhotoTextList 에 랜덤 이미지를 넣어준다.
		const photoTextList = []
		if (poseNumber === '1') {
			for (let i = 0; i < photoCount; i++) {
				photoTextList.push(c0041List[Math.floor(Math.random() * c0041List.length)])
			}
			setSelectedPhotoTextList(photoTextList)
			setSelectedText(
				c0041TextList[Math.floor(Math.random() * c0041TextList.length)]
			)
		} else if (poseNumber === '2') {
			for (let i = 0; i < photoCount; i++) {
				photoTextList.push(c0042List[Math.floor(Math.random() * c0042List.length)])
			}
			setSelectedPhotoTextList(photoTextList)
			setSelectedText(
				c0042TextList[Math.floor(Math.random() * c0042TextList.length)]
			)
		} else if (poseNumber === '3') {
			for (let i = 0; i < photoCount; i++) {
				photoTextList.push(c0043List[Math.floor(Math.random() * c0043List.length)])
			}
			setSelectedPhotoTextList(photoTextList)
			setSelectedText(
				c0043TextList[Math.floor(Math.random() * c0043TextList.length)]
			)
		} else if (poseNumber === '4') {
			for (let i = 0; i < photoCount; i++) {
				photoTextList.push(c0044List[Math.floor(Math.random() * c0044List.length)])
			}
			setSelectedPhotoTextList(photoTextList)
			setSelectedText(
				c0044TextList[Math.floor(Math.random() * c0044TextList.length)]
			)
		} else if (poseNumber === '5') {
			for (let i = 0; i < photoCount; i++) {
				photoTextList.push(c0045List[Math.floor(Math.random() * c0045List.length)])
			}
			setSelectedPhotoTextList(photoTextList)
			setSelectedText(
				c0045TextList[Math.floor(Math.random() * c0045TextList.length)]
			)
		} else if (poseNumber === '6') {
			for (let i = 0; i < photoCount; i++) {
				photoTextList.push(c0046List[Math.floor(Math.random() * c0046List.length)])
			}
			setSelectedPhotoTextList(photoTextList)
			setSelectedText(
				c0046TextList[Math.floor(Math.random() * c0046TextList.length)]
			)
		} else {
			setSelectedPhotoTextList([])
			setSelectedText('')
			setStepNumber(4)
		}
		console.log(photoTextList)
		console.log(selectedText)
	}

	const waitTimer = () => {
		setStepNumber(5)
		// wait 3 sec and setStepNumber(5)
		if (photoCount > 0) {
			setTimeout(() => {
				takeShot()
				setStepNumber(6)
			}, 5000)
		}
	}

	const location = useLocation()

	useEffect(() => {
		if (location?.state?.stepNum) {
			setStepNumber(location.state.stepNum)
			setSelectedPhotoTextList([])
			setSelectedText('')
		}
	}, [])

	return (
		<Wrapper>
			<BtnBackLink
				type="button"
				onClick={() => {
					navigate(-1)
				}}
			>
				<img
					src={BtnBack}
					alt="back"
				/>
			</BtnBackLink>
			<BtnHomeLink to="/game/area">
				<img
					src={BtnHome}
					alt="home"
				/>
			</BtnHomeLink>
			{stepNumber === 0 && (
				<motion.div animate={{ scale: [0.2, 1] }}>
					<ContentContainer>
						<TextContainer
							className="noto-sans"
							style={{ fontSize: '28px', lineHeight: '1.5' }}
						>
							<Typewriter
								options={{
									strings: `굴지 IT 대기업 대리이며, 일을 잘하기로 평판이 좋은 28세의 회사원.<br/>우연히, 그녀의 또다른 모습인 '밤 아르바이트'의 실체를 알아버리게 되었다.<br/>몇번의 협박과 회유로.. 서서히 '소하린' 그녀를 길들이기 시작하는데....`,
									autoStart: true,
									loop: false,
									delay: 30,
								}}
							/>
							{/* <TypeAnimation
							sequence={[
								'국가는 노인과 청소년의 복지향상을 위한 정책을 실시할 의무를 진다. 언론·출판에 대한 허가나 검열과 집회·결사에 대한 허가는 인정되지 아니한다. 학교교육 및 평생교육을 포함한 교육제도와 그 운영, 교육재정 및 교원의 지위에 관한 기본적인 사항은 법률로 정한다. 체장애자 및 질병·노령 기타의 사유로 생활능력이 없는 국민은 법률이 정하는 바에 의하여 국가의 보호를 받는다. 대통령은 제3항과 제4항의 사유를 지체없이 공포하여야 한다.<br />신 원장은 국회의 동의를 얻어 대통령이 임명하고, 그 임기는 4년으로 하며, 1차에 한하여 중임할 수 있다. 모든 국민은 근로의 권리를 가진다. 국가는 사회적·경제적 방법으로 근로자의 고용의 증진과 적정임금의 보장에 노력하여야 하며, 법률이 정하는 바에 의하여 최저임금제를 시행하여야 한다.<br />선거에 있어서 최고득표자가 2인 이상인 때에는 국회의 재적의원 과반수가 출석한 공개회의에서 다수표를 얻은 자를 당선자로 한다. 공무원의 신분과 정치적 중립성은 법률이 정하는 바에 의하여 보장된다.',
							]}
							wrapper="p"
							speed={1}
							style={{ fontSize: '35px' }}
						/> */}
						</TextContainer>
						<BtnContainer>
							<BtnPrev
								type="button"
								onClick={() => {
									navigate(-1)
								}}
							>
								Pre
							</BtnPrev>
							<BtnNext
								type="button"
								onClick={(e) => setStepNumber(1)}
							>
								Next
							</BtnNext>
						</BtnContainer>
					</ContentContainer>
				</motion.div>
			)}
			{(stepNumber === 1 || stepNumber === 3) && (
				<motion.div
					animate={{ opacity: [0, 1] }}
					transition={{
						duration: 1,
						delay: 0.5,
					}}
				>
					<CharImg
						src={CharacterImg}
						alt="character"
					/>
				</motion.div>
			)}
			{stepNumber === 2 && (
				<motion.div
					animate={{ opacity: [0, 1] }}
					transition={{
						duration: 1,
						delay: 0.5,
					}}
				>
					<CharImg
						src={Man01}
						alt="character"
						style={{ maxHeight: '800px' }}
					/>
				</motion.div>
			)}
			{stepNumber === 1 && (
				<motion.div
					animate={{ scale: [0.2, 1] }}
					transition={{
						delay: 0.3,
					}}
				>
					<>
						<ContentContainer2>
							<TextContainer2>
								<p className="nm">
									<b>소하린</b>
								</p>
								<Typewriter
									options={{
										strings:
											'회사에서 너무 아는체 하지마세요.. 그런 음탕한 눈빛으로 절 처다봐도 아무 것도 해드릴게 없습니다.',
										autoStart: true,
										loop: false,
										delay: 30,
									}}
								/>
							</TextContainer2>
							<BtnContainer>
								<BtnPrev
									type="button"
									onClick={() => {
										navigate(-1)
									}}
								>
									Pre
								</BtnPrev>
								<BtnNext
									type="button"
									onClick={(e) => setStepNumber(2)}
								>
									Next
								</BtnNext>
							</BtnContainer>
						</ContentContainer2>
					</>
				</motion.div>
			)}
			{stepNumber === 2 && (
				<motion.div
					animate={{ scale: [0.2, 1] }}
					transition={{
						delay: 0.3,
					}}
				>
					<>
						<ContentContainer2>
							<TextContainer2 style={{ color: '#8e44ad' }}>
								<p className="nm">
									<b style={{ color: '#000' }}>나</b>
								</p>
								<Typewriter
									options={{
										strings:
											'뻔한소리 집어치우고 회사에 소문나고 싶지 않다면 시키는 사진을 찍어서 보내도록!!<br/>흐흐흐. 시키는데로 하는것이 좋을껄....   나님을 자극해서 좋을건 없어..<br/>시키는데로 내가 원하는 사진들을 보내..<br/>내가 원하는건 말이야~',
										autoStart: true,
										loop: false,
										delay: 30,
									}}
								/>
							</TextContainer2>
							<BtnContainer>
								<BtnPrev
									type="button"
									onClick={() => {
										navigate(-1)
									}}
								>
									Pre
								</BtnPrev>
								<BtnNext
									type="button"
									onClick={(e) => setStepNumber(3)}
								>
									Next
								</BtnNext>
							</BtnContainer>
						</ContentContainer2>
					</>
				</motion.div>
			)}
			{stepNumber === 3 && (
				<motion.div
					animate={{ opacity: [0, 1] }}
					transition={{
						delay: 0.3,
					}}
				>
					<CommentList>
						<li>
							<button
								type="button"
								onClick={(e) => {
									handlePhotoClick('1')
								}}
							>
								나님을 자극하는 복장으로 유혹 샷을 보내봐
							</button>
						</li>
						<li>
							<button
								type="button"
								onClick={(e) => {
									handlePhotoClick('2')
								}}
							>
								섹시한 속옷을 입고 있는 모습 좋은데?
							</button>
						</li>
						<li>
							<button
								type="button"
								onClick={(e) => {
									handlePhotoClick('3')
								}}
							>
								속옷을 입고 자위하면 어떠려나..
							</button>
						</li>
						<li>
							<button
								type="button"
								onClick={(e) => {
									handlePhotoClick('4')
								}}
							>
								하린아! 음탕한 암캐처럼 자위해봐!
							</button>
						</li>
						<li>
							<button
								type="button"
								onClick={(e) => {
									handlePhotoClick('5')
								}}
							>
								네가 잘하는 빠는 모습을 보여봐..
							</button>
						</li>
						<li>
							<button
								type="button"
								onClick={(e) => {
									handlePhotoClick('6')
								}}
							>
								절정에 이를 때 모습 보고싶어. 흐흐
							</button>
						</li>
					</CommentList>
				</motion.div>
			)}
			{stepNumber === 4 && (
				<PhotoPointPopupWrapper>
					<motion.div
						animate={{ opacity: [0, 1] }}
						transition={{
							duration: 1,
							delay: 0.5,
						}}
					>
						<PhotoPointPopupContainer>
							<h2 className="text-center font-35">
								이 컨셉의 촬영을 위해서는 포인트가 필요합니다.
							</h2>
							<PhotoShotContainer>
								<span className="num">{photoCount}장</span>
								<button
									type="button"
									className="arr up"
									onClick={() => {
										handlePhotoCount('up')
									}}
								>
									장 수 증가
								</button>
								<ArrDown
									type="button"
									onClick={() => {
										handlePhotoCount('down')
									}}
								>
									장 수 감소
								</ArrDown>
							</PhotoShotContainer>
							<div style={{ width: '240px', margin: '0 auto' }}>
								<p
									className="font-35"
									style={{ marginBottom: '10px' }}
								>
									필요 포인트
								</p>
								<PhotoPoint>{point}</PhotoPoint>
							</div>
							<CenterBlock>
								<button
									className="btnAccept"
									type="button"
									disabled={photoCount === 0}
									onClick={(e) => {
										waitTimer()
									}}
								>
									촬영
								</button>
								<button
									className="btnCancel"
									type="button"
									onClick={(e) => {
										setStepNumber(3)
									}}
								>
									취소
								</button>
							</CenterBlock>
						</PhotoPointPopupContainer>
					</motion.div>
				</PhotoPointPopupWrapper>
			)}
			{stepNumber === 5 && (
				<PhotoPointPopupWrapper>
					<motion.div
						animate={{ opacity: [0, 1] }}
						transition={{
							duration: 1,
							delay: 0.5,
						}}
					>
						<PhotoPointPopupContainer>
							<h2
								className="text-center font-35"
								style={{ marginBottom: 70 }}
							>
								사진을 촬영하는 중입니다.
							</h2>
							<LoadingContainer>
								<LoadingDots>
									<img
										src={PhotoPrgImg}
										alt=""
									/>
								</LoadingDots>
								<img
									src={PhotoPrg}
									alt=""
									className="photoPrg"
								/>
							</LoadingContainer>
							<p
								className="text-center font-35"
								style={{ marginBottom: 70, marginTop: 60 }}
							>
								활영이 완료되면
								<br />
								안내 드리겠습니다.
							</p>
							<p className="text-center font-35">참시만 기다려주세요.</p>
						</PhotoPointPopupContainer>
					</motion.div>
				</PhotoPointPopupWrapper>
			)}
			{stepNumber === 6 && (
				<PhotoPointPopupWrapper>
					<motion.div
						animate={{ opacity: [0, 1] }}
						transition={{
							duration: 1,
							delay: 0.5,
						}}
					>
						<PhotoPointPopupContainer>
							<h2
								className="text-center font-35"
								style={{ marginBottom: 70 }}
							>
								띠링~ "사진을 촬영이 완료 되었습니다."
							</h2>
							<img
								src={PhotoDoneImg}
								alt=""
								className="center-block"
							/>
							<p
								className="text-center font-35"
								style={{ marginTop: 60 }}
							>
								바로 확인하시겠습니까?
							</p>
							<CenterBlock>
								<button
									className="btnAccept"
									type="button"
									onClick={(e) => {
										navigate('/game/area/detail/photo', {
											state: { photo: selectedPhotoTextList, text: selectedText },
										})
									}}
								>
									바로 확인
								</button>
								<button
									className="btnCancel"
									type="button"
									onClick={(e) => {
										setStepNumber(3)
										setPhotoCount(1)
										setPoint(100)
									}}
								>
									나중에 확인
								</button>
							</CenterBlock>
						</PhotoPointPopupContainer>
					</motion.div>
				</PhotoPointPopupWrapper>
			)}
		</Wrapper>
	)
}

export default AreaDetail
