import { styled } from 'styled-components'
import { ConfirmAlertTypes } from 'types'

import { motion } from 'framer-motion'

const PopupWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
`
const PopupContainer = styled.div`
	position: relative;
	padding: 50px 100px;
	background: rgba(256, 221, 220, 0.95);
	border-radius: 20px;
	max-width: 1250px;
	min-width: 320px;
`

const BtnClose = styled.button`
	position: absolute;
	top: 20px;
	right: 30px;
	width: 40px;
	height: 40px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
`

const Content = styled.div`
	.title {
		text-align: center;
		font-size: 50px;
	}
	.content {
		font-size: 25px;
		margin-top: 20px;
		margin-bottom: 50px;
	}
`

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	button,
	a {
		font-size: 40px;
		line-height: 60px;
		border-radius: 50px;
		display: block;
		min-width: 120px;
		max-width: 300px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.accept {
		background: var(--color-primary);
		color: #fff;
		margin-right: 60px;
	}
	.cancel {
		background: #333333;
		color: #fff;
		margin-left: 60px;
	}
`

const ConfrimAlert = ({
	popupIsOpen,
	popupMessageObject,
	onPopupAccept,
	onPopupCancel,
}: {
	popupIsOpen: boolean
	popupMessageObject: ConfirmAlertTypes
	onPopupAccept: () => void
	onPopupCancel: () => void
}) => {
	return (
		<>
			{popupIsOpen && (
				<PopupWrapper>
					<motion.div animate={{ y: [-110, 0], opacity: [0, 1] }}>
						<PopupContainer>
							<BtnClose type="button">
								<svg
									id="close"
									xmlns="http://www.w3.org/2000/svg"
									width="40"
									height="40"
									viewBox="0 0 40 40"
								>
									<rect
										id="사각형_177"
										data-name="사각형 177"
										width="40"
										height="40"
										fill="none"
									/>
									<line
										id="선_19"
										data-name="선 19"
										x2="30"
										y2="30"
										transform="translate(4.667 4.667)"
										fill="none"
										stroke="#333"
										strokeWidth="2"
									/>
									<line
										id="선_20"
										data-name="선 20"
										y1="30"
										x2="30"
										transform="translate(4.667 4.667)"
										fill="none"
										stroke="#333"
										strokeWidth="2"
									/>
								</svg>
							</BtnClose>
							<Content>
								<h2 className="title">{popupMessageObject.title}</h2>
								<p
									className="content"
									dangerouslySetInnerHTML={{ __html: popupMessageObject.content }}
								/>
							</Content>
							<Footer>
								<button
									type="button"
									className="accept"
									onClick={onPopupAccept}
								>
									ACCEPT
								</button>
								<button
									type="button"
									className="cancel"
									onClick={onPopupCancel}
								>
									CANCEL
								</button>
							</Footer>
						</PopupContainer>
					</motion.div>
				</PopupWrapper>
			)}
		</>
	)
}

export default ConfrimAlert
