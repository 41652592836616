import { createSlice } from '@reduxjs/toolkit'

const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: {
		info: {
			email: '',
			userId: '',
			nickName: '',
			roles: [],
		},
	},
	reducers: {
		setUserInfo: (state, actions) => {
			state.info = actions.payload
		},
		removeUserInfo: (state) => {
			state.info = {
				email: '',
				userId: '',
				nickName: '',
				roles: [],
			}
		},
	},
})

export const { setUserInfo, removeUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer
export const selectuserInfo = (state: any) => state.userInfo.info
