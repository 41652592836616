import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'

import { motion } from 'framer-motion'
import Typewriter from 'typewriter-effect'

import CsBg from 'assets/img/stage/csbg.png'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: url(${CsBg}) no-repeat center center;
	background-size: cover;
`

const Talk = () => {
	const navigate = useNavigate()

	return <Wrapper></Wrapper>
}

export default Talk
