import { useEffect, useState } from 'react'
import { styled } from 'styled-components'

import { motion } from 'framer-motion'

import MapImg from 'assets/img/stage/map.png'
import PinBlue from 'assets/img/stage/pin_blue.svg'
import PinRed from 'assets/img/stage/pin_red.svg'
import PinVl from 'assets/img/stage/pin_vl.svg'

import Photo from 'assets/img/stage/photo.png'
import Recycle from 'assets/img/stage/recycle.png'
import Option from 'assets/img/stage/option.png'

import Avt1 from 'assets/img/stage/avt1.png'
import Avt2 from 'assets/img/stage/avt2.png'
import Avt3 from 'assets/img/stage/avt3.png'
import Avt4 from 'assets/img/stage/avt4.png'
import { useNavigate } from 'react-router-dom'
import Alert from 'components/Alert'
import { ConfirmAlertTypes } from 'types'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

const Map = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
`

const Pos = styled.div`
	position: absolute;
	z-index: 1;
	width: 300px;
	.avtRow {
		display: flex;
	}
	.avt {
		width: 120px;
		height: 120px;
		border-radius: 50%;
		background-color: #ccc;
		overflow: hidden;
		display: block;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
	}
	.blue {
		padding-top: 15px;
		width: 152px;
		height: 202px;
		margin-left: auto;
		margin-right: auto;
		background: url(${PinBlue}) no-repeat 0 0;
	}
	.red {
		padding-top: 15px;
		width: 152px;
		height: 202px;
		margin-left: auto;
		margin-right: auto;
		background: url(${PinRed}) no-repeat 0 0;
	}
	.vl {
		padding-top: 15px;
		width: 152px;
		height: 202px;
		margin-left: auto;
		margin-right: auto;
		background: url(${PinVl}) no-repeat 0 0;
	}
	p {
		width: 300px;
		margin-top: 10px;
		line-height: 50px;
		border: 3px solid #fff;
		border-radius: 50px;
		text-align: center;
		font-size: 50px;
		color: #fff;
		&.posBlue {
			background: #48b6ed;
		}
		&.posVl {
			background: #7849ea;
		}
		&.posRed {
			background: #ef4545;
		}
	}
`

const OptionWrapper = styled.ul`
	position: absolute;
	right: 20px;
	top: 120px;
	z-index: 10;
	display: flex;
`

const AreaPage = () => {
	const navigate = useNavigate()
	const [popupIsOpen, setPopupIsOpen] = useState(false)
	const [popupMessageObject, setPopupMessageObject] =
		useState<ConfirmAlertTypes>({ title: '', content: '' })

	const onPopupAccept = () => {
		setPopupIsOpen(false)
		setPopupMessageObject({ title: '', content: '' })
	}

	const alertAction = () => {
		console.log(1)
		setPopupMessageObject({
			title: 'Alert',
			content: '이용하실 수 없는 등급 입니다.',
		})
		setPopupIsOpen(true)
	}

	const onItemClick = () => {
		navigate('/game/area/detail')
	}

	return (
		<Wrapper>
			<Alert
				popupIsOpen={popupIsOpen}
				popupMessageObject={popupMessageObject}
				onPopupAccept={onPopupAccept}
			/>
			<OptionWrapper>
				<li>
					<button type="button">
						<img
							src={Photo}
							alt="photos"
						/>
					</button>
				</li>
				<li>
					<button type="button">
						<img
							src={Recycle}
							alt="recycle"
						/>
					</button>
				</li>
				<li>
					<button type="button">
						<img
							src={Option}
							alt="option"
						/>
					</button>
				</li>
			</OptionWrapper>
			<motion.div
				animate={{ opacity: [0, 1] }}
				transition={{ delay: 0.5 }}
			>
				<Map>
					<img
						src={MapImg}
						alt=""
					/>
					<Pos style={{ top: 300, left: 200 }}>
						<motion.div
							animate={{ y: [0, -10, 0] }}
							transition={{
								duration: 1,
								times: [0, 0.25, 0.5],
								repeat: Infinity,
								repeatDelay: 0,
							}}
						>
							<div className="avtRow">
								<div className="blue">
									<div
										className="avt"
										onClick={onItemClick}
									>
										<img
											src={Avt1}
											alt=""
										/>
									</div>
								</div>
							</div>
						</motion.div>
						<motion.div
							animate={{ scale: [0.2, 1] }}
							transition={{ delay: 0.5 }}
						>
							<p className="posBlue">OFFICE</p>
						</motion.div>
					</Pos>
					<Pos style={{ top: 150, left: 1200 }}>
						<div className="avtRow">
							<motion.div
								animate={{ y: [0, -10, 0] }}
								transition={{
									duration: 1,
									times: [0, 0.25, 0.5],
									repeat: Infinity,
									repeatDelay: 0,
									delay: 0.25,
								}}
							>
								<div className="red">
									<div
										className="avt"
										onClick={alertAction}
									>
										<img
											src={Avt2}
											alt=""
										/>
									</div>
								</div>
							</motion.div>
							<motion.div
								animate={{ y: [0, -10, 0] }}
								transition={{
									duration: 1,
									times: [0, 0.25, 0.5],
									repeat: Infinity,
									repeatDelay: 0,
									delay: 0.5,
								}}
							>
								<div className="red">
									<div
										className="avt"
										onClick={alertAction}
									>
										<img
											src={Avt3}
											alt=""
										/>
									</div>
								</div>
							</motion.div>
						</div>
						<motion.div
							animate={{ scale: [0.2, 1] }}
							transition={{ delay: 0.5 }}
						>
							<p className="posRed">SCHOOL</p>
						</motion.div>
					</Pos>
					<Pos style={{ top: 700, left: 900 }}>
						<motion.div
							animate={{ y: [0, -10, 0] }}
							transition={{
								duration: 1,
								times: [0, 0.25, 0.5],
								repeat: Infinity,
								repeatDelay: 0,
								delay: 0.75,
							}}
						>
							<div className="vl">
								<div className="avtRow">
									<div
										className="avt"
										onClick={alertAction}
									>
										<img
											src={Avt4}
											alt=""
										/>
									</div>
								</div>
							</div>
						</motion.div>
						<motion.div
							animate={{ scale: [0.2, 1] }}
							transition={{ delay: 0.5 }}
						>
							<p className="posVl">STREET</p>
						</motion.div>
					</Pos>
				</Map>
			</motion.div>
		</Wrapper>
	)
}

export default AreaPage
