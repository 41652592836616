import { useEffect, useState, Suspense } from 'react'

import { Routes, Route } from 'react-router-dom'

import BlankLayout from 'layouts/BlankLayout'
import InGameLayout from 'layouts/InGameLayout'

import LandingPage from 'pages/landing'
import LoginPage from 'pages/login'
import SignUpPage from 'pages/signup'
import AreaDetailPage from 'pages/area/detail'
import TalkPage from 'pages/area/talk'
import PhotoViewPage from 'pages/area/detail/photo'
import MyPage from 'pages/mypage'
import AboutPage from 'pages/about'

import AreaPage from 'pages/area'

import styled from 'styled-components'
import RequireAuth from 'components/RequireAuth'

const Portrait = styled.div`
	@media screen and (orientation: portrait) {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9999;
		background: #000;
	}
`

const ROLES = {
	ROLE_USER: 1,
	ROLE_ADMIN: 3,
	ROLE_SYSTEM: 2,
}

function App() {
	// detect ladscape or portrait
	const [isLandscape, setIsLandscape] = useState<boolean>(false)
	const handleOrientationChange = () => {
		console.log('handleOrientationChange')
		if (window.matchMedia('(orientation: landscape)').matches) {
			setIsLandscape(true)
		} else {
			setIsLandscape(false)
		}
	}
	useEffect(() => {
		handleOrientationChange()
		window.addEventListener('orientationchange', handleOrientationChange)
		return () => {
			window.removeEventListener('orientationchange', handleOrientationChange)
		}
	}, [])

	return (
		<Suspense fallback={<div />}>
			<Portrait />
			<Routes>
				<Route
					path="/"
					element={
						<BlankLayout>
							<LandingPage />
						</BlankLayout>
					}
				/>
				<Route
					path="/login"
					element={
						<BlankLayout>
							<LoginPage />
						</BlankLayout>
					}
				/>
				<Route
					path="/signup"
					element={
						<BlankLayout>
							<SignUpPage />
						</BlankLayout>
					}
				/>
				<Route
					path="/about"
					element={
						<InGameLayout>
							<AboutPage />
						</InGameLayout>
					}
				/>
				<Route
					path="/game"
					element={
						<InGameLayout>
							<RequireAuth
								allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
							/>
						</InGameLayout>
					}
				>
					<Route
						path="/game/area"
						element={<AreaPage />}
					/>
					<Route
						path="/game/area/detail"
						element={<AreaDetailPage />}
					/>
					<Route
						path="/game/area/detail/photo"
						element={<PhotoViewPage />}
					/>
					<Route
						path="/game/area/talk"
						element={<TalkPage />}
					/>
					<Route
						path="/game/mypage"
						element={<MyPage />}
					/>
				</Route>
			</Routes>
		</Suspense>
	)
}

export default App
