import { styled } from 'styled-components'
import PointIco from 'assets/img/common/point_ico.svg'

export const VerticalFlex = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
`

/**
 * forms
 */
export const InputText = styled.input`
	height: 60px;
	font-size: 40px;
	background-color: #fff;
	border-radius: 100px;
	border: 0;
	padding: 0 40px;
	outline: none;
`

export const CenterBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 1199px) {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		button {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
`

export const FlexJustifyBetween = styled.div`
	display: flex;
	justify-content: space-between;
`

export const PhotoPointPopupWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-bottom: 60px;
`
export const PhotoPointPopupContainer = styled.div`
	position: relative;
	padding: 80px;
	border-radius: 30px;
	min-width: 300px;
	width: 1550px;
	max-width: 1600px;
	background: rgb(255, 200, 170);
	background: linear-gradient(
		270deg,
		rgba(255, 200, 170, 1) 0%,
		rgba(242, 184, 184, 1) 100%
	);
	.btnAccept,
	.btnCancel {
		display: block;
		width: 400px;
		height: 60px;
		color: #fff;
		border-radius: 100px;
		text-align: center;
		font-size: 40px;
	}
	.btnAccept {
		background: #e86262;
		margin-bottom: 25px;
		margin-top: 30px;
	}
	.btnCancel {
		background: #ce7c8f;
	}
	@media (max-width: 1199px) {
		padding: 40px 40px 20px 40px;
		width: 100%;
	}
`
export const PhotoShotContainer = styled.div`
	width: 240px;
	height: 240px;
	background: #fff;
	border-radius: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #e86262;
	font-size: 40px;
`
export const PhotoPoint = styled.p`
	position: relative;
	line-height: 40px;
	background: #fff;
	border-radius: 50px;
	text-align: center;
	width: 240px;
	margin: 0 auto;
	font-size: 30px;
	padding-right: 40px;
	&:after {
		content: '';
		display: block;
		clear: both;
		width: 40px;
		height: 25px;
		background: url(${PointIco}) no-repeat center;
		position: absolute;
		top: 8px;
		right: 8px;
	}
`
