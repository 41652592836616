import Logo from 'assets/img/signup/logo.png'
import { FlexJustifyBetween, VerticalFlex } from 'components/Styles'
import { styled } from 'styled-components'
import { useNavigate, Link } from 'react-router-dom'

import { motion } from 'framer-motion'

import SignupBg from 'assets/img/signup/signup_bg.png'
import Email from 'assets/img/signup/email.png'
import Id from 'assets/img/signup/id.png'
import Password from 'assets/img/signup/psw.png'
import Password2 from 'assets/img/signup/psw-2.png'
import Nick from 'assets/img/signup/nick.png'

const Wrapper = styled.div`
	position: relative;
	height: 100vh;
	background-color: #fff;
	.top {
		background-image: url(${SignupBg});
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100vw;
		height: 263px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

const Form = styled.form`
	margin: 0 auto;
	padding-top: 60px;
	text-align: center;
	width: 400px;
	h2 {
		font-size: 50px;
	}
	.btnSubmit {
		color: #fff;
		background: var(--color-primary);
		font-size: 50px;
		border-radius: 50px;
		width: 100%;
		display: block;
		margin-top: 40px;
	}
`
const Input = styled.div`
	position: relative;
	margin-bottom: 25px;
	label {
		position: absolute;
		top: 10px;
		left: 20px;
		width: 65px;
		height: 40px;
		overflow: hidden;
	}
	input {
		padding-left: 100px;
		height: 60px;
		background-color: #fff;
		color: #ce7c8f;
		font-size: 30px;
		width: 100%;
		border-radius: 50px;
		border: 2px solid var(--color-primary);
		&.id {
			margin-bottom: 25px;
		}
	}
`

const SignUp = () => {
	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
	}

	return (
		<Wrapper>
			<div className="top">
				<motion.div
					animate={{
						scale: [1, 1.5, 1],
						rotate: [0, 0, 360],
						borderRadius: ['20%', '20%', '50%'],
					}}
				>
					<h1>
						<img
							src={Logo}
							alt="logo"
						/>
					</h1>
				</motion.div>
			</div>
			<motion.div
				animate={{
					opacity: [0, 1],
					translateY: [100, 0],
				}}
			>
				<Form onSubmit={onSubmit}>
					<h2
						className="primary"
						style={{ marginBottom: 50 }}
					>
						Create An Account
					</h2>
					<Input>
						<label htmlFor="email">
							<img
								src={Email}
								alt=""
							/>
						</label>
						<input
							type="email"
							id="email"
							placeholder="E-Mail"
						/>
					</Input>
					<Input>
						<label htmlFor="id">
							<img
								src={Id}
								alt=""
							/>
						</label>
						<input
							type="text"
							id="id"
							placeholder="ID"
						/>
					</Input>
					<Input>
						<label htmlFor="password">
							<img
								src={Password}
								alt=""
							/>
						</label>
						<input
							type="password"
							id="password"
							placeholder="PASSWORD"
						/>
					</Input>
					<Input>
						<label htmlFor="password2">
							<img
								src={Password2}
								alt=""
							/>
						</label>
						<input
							type="password"
							id="password2"
							placeholder="CONFIRM PASSWORD"
						/>
					</Input>
					<Input>
						<label htmlFor="nickname">
							<img
								src={Nick}
								alt=""
							/>
						</label>
						<input
							type="text"
							id="nickname"
							placeholder="NICKNAME"
						/>
					</Input>
					<div style={{ paddingLeft: 40, paddingRight: 40 }}>
						<button
							type="submit"
							className="btnSubmit"
						>
							SUBMIT
						</button>
					</div>
				</Form>
			</motion.div>
		</Wrapper>
	)
}

export default SignUp
