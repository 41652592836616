import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate, Link, useLocation } from 'react-router-dom'

import { motion } from 'framer-motion'
import Typewriter from 'typewriter-effect'

import CsBg from 'assets/img/stage/csbg.png'
import CharacterImg from 'assets/img/stage/photo1.png'
import Gallery from 'assets/img/stage/gallery.svg'
import Recycle from 'assets/img/stage/recycle.svg'
import Option from 'assets/img/stage/option.svg'

import BtnHome from 'assets/img/common/btn_home.png'
import BtnBack from 'assets/img/common/btn_back.png'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import RefreshIcon from 'assets/img/refresh_icon.png'
import Frame from 'assets/img/frame.png'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	overflow: auto;
`

const BtnHomeLink = styled(Link)`
	position: absolute;
	top: 210px;
	right: 80px;
`

const BtnBackLink = styled.button`
	position: absolute;
	top: 210px;
	left: 80px;
`

const PhotoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	overflow-y: auto;
	padding-top: 200px;
`
const PhotoContainer = styled.div`
	position: relative;
	margin: 0 auto;
	width: 500px;
	height: 800px;
	padding: 77px 27px 85px 27px;
	background: url(${Frame}) no-repeat 0 0;
	background-size: 500px auto;
	p {
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.inner {
		height: 660px;
		overflow: hidden;
	}
	.info {
		position: absolute;
		bottom: 25px;
		left: 25px;
		right: 25px;
		background: rgba(0, 0, 0, 0.5);
		padding: 30px;
		color: #fff;
		border-radius: 20px;
		.btns {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 40px;
			margin-bottom: 40px;
			button {
				margin-left: 10px;
			}
		}
	}
	.slick-arrow:before {
		color: red;
		font-size: 30px;
	}
	.slick-next {
		right: 20px;
		z-index: 9999;
	}
	.slick-prev {
		left: 10px;
		z-index: 9999;
	}
`

const RefIcon = styled.button`
	position: absolute;
	bottom: 20px;
	right: -70px;
	img {
		width: 50px;
	}
`

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: true,
}

const PhotoView = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [photoUrlList, setPhotoUrlList] = useState<any[]>([])
	const [photoText, setPhotoText] = useState('')

	const historyBack = () => {
		navigate('/game/area/detail', {
			state: { stepNum: 3 },
		})
	}

	useEffect(() => {
		// get state from location
		console.log('location.state', location?.state)
		if (location?.state?.photo) {
			console.log(location?.state?.photo.length)
			setPhotoUrlList(location?.state?.photo)
			setPhotoText(location?.state?.text)
		} else {
			// navigate to area page
			navigate('/game/area')
		}
	}, [])

	return (
		<Wrapper>
			<BtnBackLink
				type="button"
				onClick={() => {
					navigate(-1)
				}}
			>
				<img
					src={BtnBack}
					alt="back"
				/>
			</BtnBackLink>
			<BtnHomeLink to="/game/area">
				<img
					src={BtnHome}
					alt="home"
				/>
			</BtnHomeLink>
			<motion.div
				animate={{ opacity: [0, 1] }}
				transition={{
					duration: 1,
					delay: 0.5,
				}}
			>
				<PhotoWrapper>
					<PhotoContainer>
						<div className="inner">
							{photoUrlList.length > 1 ? (
								<Slider {...settings}>
									{photoUrlList &&
										photoUrlList.map((photoUrl, index) => (
											<img
												key={index}
												src={photoUrl}
												alt=""
												style={{ maxWidth: '100%', maxHeight: '800px' }}
											/>
										))}
								</Slider>
							) : (
								<img
									src={photoUrlList[0]}
									alt=""
									style={{ maxWidth: '100%', maxHeight: '800px' }}
								/>
							)}
						</div>
						<RefIcon
							type="button"
							onClick={historyBack}
						>
							<img
								src={RefreshIcon}
								alt=""
							/>
						</RefIcon>
						<div className="info">
							<p className="font-30">{photoText}</p>
							<div className="btns">
								<button
									type="button"
									onClick={() => {
										navigate('/game/mypage', {
											state: { name: 'gallery', photo: photoUrlList, text: photoText },
										})
									}}
								>
									<img
										src={Gallery}
										alt=""
									/>
								</button>
								<button
									type="button"
									onClick={() => {
										navigate('/game/mypage', {
											state: { name: 'recycle', photo: photoUrlList, text: photoText },
										})
									}}
								>
									<img
										src={Recycle}
										alt=""
									/>
								</button>
								<button
									type="button"
									onClick={() => {
										navigate('/game/mypage', {
											state: { name: 'option', photo: photoUrlList, text: photoText },
										})
									}}
								>
									<img
										src={Option}
										alt=""
									/>
								</button>
							</div>
						</div>
					</PhotoContainer>
				</PhotoWrapper>
			</motion.div>
		</Wrapper>
	)
}

export default PhotoView
