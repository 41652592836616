import Logo from 'assets/img/common/Logo.png'
import { VerticalFlex } from 'components/Styles'
import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { motion } from 'framer-motion'

import LandingAvatar from 'assets/img/tmp/landing_avatar.png'

const Wrapper = styled.div`
	background: rgb(255, 200, 170);
	background: linear-gradient(
		0deg,
		rgba(255, 200, 170, 1) 0%,
		rgba(242, 184, 184, 1) 100%
	);
`

const LinkButton = styled.button`
	position: relative;
	width: 400px;
	line-height: 60px;
	background-color: #fff;
	outline: 0;
	border: 0;
	border-radius: 50px;
	font-size: 40px;
	text-align: left;
	padding-left: 56px;
	margin-top: 150px;
	margin-bottom: 20px;
	color: #000;
	span {
		position: absolute;
		right: 10px;
		top: 7px;
	}
	&:before {
		content: '';
		position: absolute;
		top: 15px;
		left: 40px;
		width: 1px;
		height: 30px;
		background-color: #595959;
	}
	@media (max-width: 767px) {
		display: block;
		max-width: 320px;
	}
`

const AvatarContainer = styled.div`
	width: 480px;
	height: 80px;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 30px;
	margin-bottom: 80px;
	display: flex;
	align-items: center;
	padding: 15px;
	position: relative;
	.sq {
		position: absolute;
		top: 10px;
		left: 15px;
		background-color: #e86262;
		border-radius: 50%;
		width: 62px;
		height: 62px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.txt {
		padding-left: 85px;
		.font-bold {
			font-size: 30px;
		}
		.font-light {
			font-size: 25px;
		}
	}
	@media (max-width: 767px) {
		width: 100%;
		display: block;
		.sq {
		}
		.txt {
		}
	}
`

const LogoImg = styled.div`
	@media (max-width: 767px) {
		img {
			max-width: 300px;
		}
	}
`

const LandingPage = () => {
	const navigate = useNavigate()

	window.addEventListener('keyup', () => {
		navigate('/login')
	})

	return (
		<Wrapper
			onClick={() => {
				navigate('/login')
			}}
		>
			<VerticalFlex>
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1, rotateZ: 360 }}
				>
					<AvatarContainer>
						<div className="sq">
							<img
								src={LandingAvatar}
								alt="avatar"
							/>
						</div>
						<div className="txt">
							<h2 className="font-bold">Lorem ipsum</h2>
							<h3 className="font-light">Loren ipsum</h3>
						</div>
					</AvatarContainer>
				</motion.div>
				<motion.div
					animate={{ scale: [1, 1.5, 1] }}
					transition={{
						duration: 3,
						times: [0, 0.2, 1],
						repeat: Infinity,
						repeatDelay: 0.5,
					}}
				>
					<LogoImg>
						<img
							src={Logo}
							alt="logo"
						/>
					</LogoImg>
				</motion.div>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.8, delay: 0.13 }}
				>
					<motion.div
						whileHover={{ scale: 1.2 }}
						whileTap={{ scale: 0.9 }}
						transition={{ type: 'spring', stiffness: 400, damping: 17 }}
					>
						<LinkButton
							type="button"
							className="font-light"
						>
							ENTER
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="75"
									height="48"
									viewBox="0 0 75 48"
								>
									<path
										id="패스_177"
										data-name="패스 177"
										d="M1135.626,674.7h-45.344a14.821,14.821,0,0,0-14.827,14.814v18.373a14.821,14.821,0,0,0,14.827,14.814h45.344a14.821,14.821,0,0,0,14.828-14.814V689.513A14.821,14.821,0,0,0,1135.626,674.7Zm-10.612,24.83-10.733,10.723a1.881,1.881,0,0,1-2.655,0l-10.733-10.723a6.888,6.888,0,0,1-.165-9.689,6.761,6.761,0,0,1,9.571-.013l2.626,2.623c.008.008.019,0,.029,0s.021.008.029,0l2.626-2.623a6.761,6.761,0,0,1,9.571.013A6.887,6.887,0,0,1,1125.014,699.529Z"
										transform="translate(-1075.454 -674.699)"
										fill="#e86262"
									/>
								</svg>
							</span>
						</LinkButton>
					</motion.div>
					<h1 className="font-light font-35 text-center">Click any key to Enter</h1>
				</motion.div>
			</VerticalFlex>
		</Wrapper>
	)
}
export default LandingPage
