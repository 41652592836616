import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { ConfirmAlertTypes } from 'types'
import Alert from 'components/Alert'

import BtnHome from 'assets/img/common/btn_home.png'
import BtnBack from 'assets/img/common/btn_back.png'
import Gallery from 'assets/img/mypage/photo.svg'
import Recycle from 'assets/img/mypage/recycle.svg'
import Option from 'assets/img/mypage/option.svg'
import Expand from 'assets/img/mypage/expand.svg'
import Photo1 from 'assets/img/mypage/photo1.png'
import Blank from 'assets/img/mypage/blank.png'
import My1 from 'assets/img/mypage/mi1.png'
import My2 from 'assets/img/mypage/mi2.png'
import ActiveImg from 'assets/img/mypage/active.png'
import ConfrimAlert from 'components/ConfirmAlert'

import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
`

const BtnHomeLink = styled(Link)`
	position: absolute;
	top: 210px;
	right: 80px;
`

const BtnBackLink = styled.button`
	position: absolute;
	top: 210px;
	left: 80px;
`

const TabContainer = styled.div`
	background: #e6e6e6;
	padding: 30px;
	position: absolute;
	bottom: 0;
	width: 640px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	left: 50%;
	transform: translateX(-50%);
	.tabHeader {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		button {
			margin-left: 10px;
			position: relative;
			&.active:before {
				content: '';
				position: absolute;
				top: -5px;
				left: 0;
				width: 100%;
				height: 3px;
				background: #ef4545;
			}
		}
	}
	.tabBody {
		margin-top: 20px;
		max-height: 60vh;
		overflow-y: auto;
		.item {
			margin-bottom: 20px;
		}
		.itemHeader {
			text-align: center;
			font-size: 50px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			line-height: 50px;
			border-radius: 50px;
			position: relative;
			z-index: 10;
			padding-right: 20px;
			&.red {
				background: #ef4545;
				.ico {
					color: #ef4545;
				}
			}
			&.blue {
				background: #4f9fff;
				.ico {
					color: #4f9fff;
				}
			}
			&.vl {
				background: #7849ea;
				.ico {
					color: #7849ea;
				}
			}
			&.pnk {
				background: #e945ef;
				.ico {
					color: #e945ef;
				}
			}
			&.grn {
				background: #488b15;
				.ico {
					color: #488b15;
				}
			}
			.ico {
				background: #fff;
				display: block;
				width: 44px;
				height: 44px;
				diaplay: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				font-size: 27px;
				position: relative;
				&.new:before {
					position: absolute;
					width: 12px;
					height: 12px;
					display: block;
					background: #f95454;
					left: 0;
					top: 0;
					z-index: 11;
					content: '';
					border-radius: 50%;
				}
			}
			.expand {
				width: 35px;
				height: 35px;
				diaplay: flex;
				justify-content: center;
				align-items: center;
				transition: transform 0.15s ease-in-out;
			}
			&.active {
				.expand {
					transform: rotate(90deg);
				}
			}
		}
		.itemList {
			display: none;
			align-items: center;
			justify-content: space-between;
			background: #555555;
			border-bottom-left-radius: 30px;
			border-bottom-right-radius: 30px;
			padding: 40px 35px 25px 35px;
			transform: translateY(-20px);
			opacity: 0;
			transition: all 0.15s ease-in-out;
			max-height: 0;
			.slick-list {
				width: 500px;
				heightL 94px;
			}
			li {
				position: relative;
				width: 94px;
				height: 94px;
				background: url(${Blank}) no-repeat center center;
				.activeImg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			&.active {
				display: flex;
				max-height: 100%;
				opacity: 1;
			}
		}
	}
`

const MyPageContainer = styled.div`
	background: #fff;
	padding-left: 40px;
	padding-right: 40px;
	.mypage {
		margin-left: -40px;
		margin-right: -40px;
		font-size: 50px;
		color: #fff;
		background: #e86262;
		text-align: center;
	}
	.greet {
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 30px;
		color: #ce7c8f;
		span {
			background: #ce7c8f;
			color: #fff;
			border-radius: 50px;
			padding-left: 50px;
			padding-right: 50px;
			text-align: center;
		}
	}
	.frm {
		border-top: 1px solid #ce7c8f;
		padding-top: 30px;
		margin-top: 30px;
		padding-left: 50px;
		padding-right: 50px;
		.rel {
			position: relative;
			img {
				position: absolute;
				top: 50%;
				left: 20px;
				transform: translateY(-50%);
			}
		}
		label {
			font-size: 30px;
			color: #ce7c8f;
			display: block;
			width: 100%;
			margin-bottom: 20px;
			transform: translateX(-50px);
		}
		input {
			display: block;
			width: 100%;
			border: 1px solid #ce7c8f;
			border-radius: 50px;
			height: 60px;
			padding-left: 100px;
			padding-right: 20px;
			font-size: 30px;
			color: #ce7c8f;
			margin-bottom: 20px;
		}
	}
	.deposit {
		display: block;
		width: 100%;
		line-height: 40px;
		font-size: 30px;
		color: #fff;
		background: #e86262;
		border-radius: 50px;
		margin-bottom: 20px;
	}
	.history {
		display: block;
		width: 100%;
		line-height: 40px;
		font-size: 30px;
		color: #fff;
		background: #ce7c8f;
		border-radius: 50px;
		margin-bottom: 50px;
	}
	.leaveOut {
		font-size: 20px;
		line-height: 44px;
		border: 1px solid #ce7c8f;
		border-radius: 50px;
		color: #ce7c8f;
		width: 200px;
	}
`
const SelectCount = styled.div`
	position: relative;
	background: #488b15;
	color: #fff;
	border-radius: 50px;
	padding-left: 75px;
	padding-right: 75px;
	padding-top: 15px;
	padding-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.cnt {
		font-size: 30px;
	}
	.sum {
		font-size: 50px;
	}
	.divider {
		width: 1px;
		background: #fff;
		height: 45px;
	}
`

const imgList = [
	{
		id: 1,
		src: Photo1,
	},
	{
		id: 2,
		src: Photo1,
	},
	{
		id: 3,
		src: Photo1,
	},
	{
		id: 4,
		src: Photo1,
	},
	{
		id: 5,
		src: Photo1,
	},
	{
		id: 6,
		src: Photo1,
	},
	{
		id: 7,
		src: Photo1,
	},
]

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 5,
	arrows: true,
}

const MyPage = () => {
	const location = useLocation()
	const navigate = useNavigate()

	const [activeNum, setActiveNum] = useState<number>(2)
	const [active2Num, setActive2Num] = useState<number>(0)

	const [selectedCount, setSelectedCount] = useState<number>(0)

	const [checkedItems, setCheckedItems] = useState(new Map())

	const [tabNum, setTabNum] = useState<number>(0)
	const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false)
	const [popupMessageObject, setPopupMessageObject] =
		useState<ConfirmAlertTypes>({
			title: '',
			content: '',
		})
	const onPopupAccept = () => {
		setPopupIsOpen(false)
		setPopupMessageObject({ title: '', content: '' })
	}

	const itemSelectAction = (id: number) => {
		setCheckedItems(checkedItems.set(id, !checkedItems.get(id)))
		setSelectedCount(checkedItems.get(id) ? selectedCount + 1 : selectedCount - 1)
	}

	const [confirmPopupIsOpen, setConfirmPopupIsOpen] = useState<boolean>(false)
	const [confirmPopupMessageObject, setConfirmPopupMessageObject] =
		useState<ConfirmAlertTypes>({
			title: '',
			content: '',
		})
	const onConfirmPopupAccept = () => {
		setConfirmPopupIsOpen(false)
		setConfirmPopupMessageObject({ title: '', content: '' })
	}
	const onConfirmPopupCancel = () => {
		setConfirmPopupIsOpen(false)
		setConfirmPopupMessageObject({ title: '', content: '' })
	}

	const recycleAction = () => {
		if (checkedItems.size === 0) {
			setPopupMessageObject({
				title: '선택된 사진이 없습니다.',
				content: '사진을 선택해주세요.',
			})
			setPopupIsOpen(true)
		} else {
			setConfirmPopupMessageObject({
				title: '',
				content: '전환하시면 선택한 사진이 사라집니다.<br />정말 전환하시겠습니까?',
			})
			setConfirmPopupIsOpen(true)
		}
	}

	const [photoUrlList, setPhotoUrlList] = useState<any[]>([])
	const [photoText, setPhotoText] = useState('')
	const [photoZoomOpen, setPhotoZoomOpen] = useState<boolean>(false)
	const [photoZoomUrl, setPhotoZoomUrl] = useState<string>('')

	const onItemClick = (index: number) => {
		setPhotoZoomUrl(photoUrlList[index])
		setPhotoZoomOpen(true)
	}

	useEffect(() => {
		console.log(location.state)
		const { name } = location.state
		if (location?.state?.photo) {
			setPhotoUrlList(location?.state?.photo)
			setPhotoText(location?.state?.text)
		}
		if (name === 'gallery') {
			setTabNum(0)
		} else if (name === 'recycle') {
			setTabNum(1)
		} else if (name === 'option') {
			setTabNum(2)
		} else {
			setPopupMessageObject({
				title: '잘못된 접근입니다.',
				content: '홈으로 이동합니다.',
			})
			setPopupIsOpen(true)
		}
	}, [])

	return (
		<>
			{photoZoomOpen && (
				<Wrapper>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							background: 'rgba(0, 0, 0, 0.5)',
							zIndex: 100,
						}}
					>
						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
							}}
						>
							<img
								src={photoZoomUrl}
								alt=""
								style={{ maxHeight: '80vh' }}
							/>
							<button
								type="button"
								style={{
									background: 'var(--color-primary)',
									color: '#fff',
									marginRight: '60px',
									fontSize: '40px',
									display: 'block',
									padding: '5px 70px',
									marginTop: '10px',
									borderRadius: '50px',
								}}
								onClick={() => {
									setPhotoZoomOpen(false)
									setPhotoZoomUrl('')
								}}
							>
								닫기
							</button>
						</div>
					</div>
				</Wrapper>
			)}
			<ConfrimAlert
				popupIsOpen={confirmPopupIsOpen}
				popupMessageObject={confirmPopupMessageObject}
				onPopupAccept={onConfirmPopupAccept}
				onPopupCancel={onConfirmPopupCancel}
			/>
			<Alert
				popupIsOpen={popupIsOpen}
				popupMessageObject={popupMessageObject}
				onPopupAccept={onPopupAccept}
			/>
			<Wrapper>
				<BtnBackLink
					type="button"
					onClick={() => {
						navigate(-1)
					}}
				>
					<img
						src={BtnBack}
						alt="back"
					/>
				</BtnBackLink>
				<BtnHomeLink to="/game/area">
					<img
						src={BtnHome}
						alt="home"
					/>
				</BtnHomeLink>
				<TabContainer>
					<div className="tabHeader">
						<button
							type="button"
							className={tabNum === 0 ? 'active' : ''}
							onClick={() => {
								setTabNum(0)
							}}
						>
							<img
								src={Gallery}
								alt=""
							/>
						</button>
						<button
							type="button"
							className={tabNum === 1 ? 'active' : ''}
							onClick={() => {
								setTabNum(1)
							}}
						>
							<img
								src={Recycle}
								alt=""
							/>
						</button>
						<button
							type="button"
							className={tabNum === 2 ? 'active' : ''}
							onClick={() => {
								setTabNum(2)
							}}
						>
							<img
								src={Option}
								alt=""
							/>
						</button>
					</div>
					{tabNum === 0 && (
						<div className="tabBody">
							<div className="item">
								<button
									className={
										activeNum === 0 ? 'itemHeader red active' : 'itemHeader red'
									}
									onClick={() => {
										setActiveNum(0)
									}}
									type="button"
								>
									<span className="ico fin">FIN</span>
									SCHOOL 01
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 0 ? 'itemList active' : 'itemList'}>
									<li>
										<img
											src={Photo1}
											alt=""
										/>
									</li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
							</div>
							<div className="item">
								<button
									className={
										activeNum === 1 ? 'itemHeader red active' : 'itemHeader red'
									}
									onClick={() => {
										setActiveNum(1)
									}}
									type="button"
								>
									<span className="ico fin">FIN</span>
									SCHOOL 02
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 1 ? 'itemList active' : 'itemList'}>
									<li>
										<img
											src={Photo1}
											alt=""
										/>
									</li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
							</div>
							<div className="item">
								<button
									className={
										activeNum === 2 ? 'itemHeader blue active' : 'itemHeader blue'
									}
									onClick={() => {
										setActiveNum(2)
									}}
									type="button"
								>
									<span className="ico ing">ING</span>
									OFFICE
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 2 ? 'itemList active' : 'itemList'}>
									<Slider {...settings}>
										{photoUrlList &&
											photoUrlList.map((item, index) => (
												<li key={index}>
													<img
														src={item}
														style={{ width: 94 }}
														alt=""
														onClick={() => {
															onItemClick(index)
														}}
													/>
												</li>
											))}
									</Slider>
								</ul>
							</div>
							<div className="item">
								<button
									className={activeNum === 3 ? 'itemHeader vl active' : 'itemHeader vl'}
									onClick={() => {
										setActiveNum(3)
									}}
									type="button"
								>
									<span className="ico ing">ING</span>
									STREET
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 3 ? 'itemList active' : 'itemList'}>
									<li>
										<img
											src={Photo1}
											alt=""
										/>
									</li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
							</div>
							<div className="item">
								<button
									className={
										activeNum === 4 ? 'itemHeader pnk active' : 'itemHeader pnk'
									}
									onClick={() => {
										setActiveNum(4)
									}}
									type="button"
								>
									<span className="ico new">NEW</span>
									HOTEL
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 4 ? 'itemList active' : 'itemList'}>
									<li>
										<img
											src={Photo1}
											alt=""
										/>
									</li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
							</div>
							<div className="item">
								<button
									className={activeNum === 5 ? 'itemHeader vl active' : 'itemHeader vl'}
									onClick={() => {
										setActiveNum(5)
									}}
									type="button"
								>
									<span className="ico new">NEW</span>
									CLUB
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 5 ? 'itemList active' : 'itemList'}>
									<li>
										<img
											src={Photo1}
											alt=""
										/>
									</li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
							</div>
							<div className="item">
								<button
									className={
										activeNum === 6 ? 'itemHeader grn active' : 'itemHeader grn'
									}
									onClick={() => {
										setActiveNum(6)
									}}
									type="button"
								>
									<span className="ico new">NEW</span>
									OUTDOOR
									<span className="expand">
										<img
											src={Expand}
											alt=""
										/>
									</span>
								</button>
								<ul className={activeNum === 6 ? 'itemList active' : 'itemList'}>
									<li>
										<img
											src={Photo1}
											alt=""
										/>
									</li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
							</div>
						</div>
					)}

					{tabNum === 1 && (
						<>
							<div
								className="tabBody"
								style={{ height: '50vh' }}
							>
								<div className="item">
									<button
										className={
											active2Num === 0 ? 'itemHeader red active' : 'itemHeader red'
										}
										onClick={() => {
											setActive2Num(0)
										}}
										type="button"
									>
										<span className="ico fin">FIN</span>
										SCHOOL 01
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 0 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(1)
											}}
										>
											{checkedItems.get(1) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div className="item">
									<button
										className={
											active2Num === 1 ? 'itemHeader red active' : 'itemHeader red'
										}
										onClick={() => {
											setActive2Num(1)
										}}
										type="button"
									>
										<span className="ico fin">FIN</span>
										SCHOOL 02
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 1 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(2)
											}}
										>
											{checkedItems.get(2) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div className="item">
									<button
										className={
											active2Num === 2 ? 'itemHeader blue active' : 'itemHeader blue'
										}
										onClick={() => {
											setActive2Num(2)
										}}
										type="button"
									>
										<span className="ico ing">ING</span>
										OFFICE
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 2 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(3)
											}}
										>
											{checkedItems.get(3) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div className="item">
									<button
										className={
											active2Num === 3 ? 'itemHeader vl active' : 'itemHeader vl'
										}
										onClick={() => {
											setActive2Num(3)
										}}
										type="button"
									>
										<span className="ico ing">ING</span>
										STREET
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 3 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(4)
											}}
										>
											{checkedItems.get(4) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div className="item">
									<button
										className={
											active2Num === 4 ? 'itemHeader pnk active' : 'itemHeader pnk'
										}
										onClick={() => {
											setActive2Num(4)
										}}
										type="button"
									>
										<span className="ico new">NEW</span>
										HOTEL
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 4 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(5)
											}}
										>
											{checkedItems.get(5) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div className="item">
									<button
										className={
											active2Num === 5 ? 'itemHeader vl active' : 'itemHeader vl'
										}
										onClick={() => {
											setActive2Num(5)
										}}
										type="button"
									>
										<span className="ico new">NEW</span>
										CLUB
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 5 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(6)
											}}
										>
											{checkedItems.get(6) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
								<div className="item">
									<button
										className={
											active2Num === 6 ? 'itemHeader grn active' : 'itemHeader grn'
										}
										onClick={() => {
											setActive2Num(6)
										}}
										type="button"
									>
										<span className="ico new">NEW</span>
										OUTDOOR
										<span className="expand">
											<img
												src={Expand}
												alt=""
											/>
										</span>
									</button>
									<ul className={active2Num === 6 ? 'itemList active' : 'itemList'}>
										<li
											onClick={() => {
												itemSelectAction(7)
											}}
										>
											{checkedItems.get(7) && (
												<img
													src={ActiveImg}
													alt=""
													className="activeImg"
												/>
											)}
											<img
												src={Photo1}
												alt=""
											/>
										</li>
										<li></li>
										<li></li>
										<li></li>
										<li></li>
									</ul>
								</div>
							</div>
							<SelectCount onClick={recycleAction}>
								<p className="cnt">{selectedCount}장 선택됨</p>
								<div className="divider" />
								<p className="sum">{selectedCount * 1000}P</p>
							</SelectCount>
						</>
					)}
					{tabNum === 2 && (
						<div className="tabBody">
							<MyPageContainer>
								<h2 className="mypage">MY PAGE</h2>
								<p className="greet">
									반갑습니다. <span> 테스트맨</span>
								</p>
								<div className="frm">
									<label htmlFor="email">사용자 계정</label>
									<div className="rel">
										<img
											src={My1}
											alt=""
										/>
										<input
											type="text"
											value="TEST000000@gmail.com"
											readOnly
										/>
									</div>
									<label htmlFor="">보유 포인트</label>
									<div className="rel">
										<img
											src={My2}
											alt=""
										/>
										<input
											type="text"
											value="999,999,999"
											readOnly
										/>
									</div>
									<button
										type="button"
										className="deposit"
									>
										충전하기
									</button>
									<button
										type="button"
										className="history"
									>
										포인트 충전 & 사용 내역
									</button>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
											paddingBottom: '40px',
										}}
									>
										<button
											type="button"
											className="leaveOut"
										>
											탈퇴하기
										</button>
									</div>
								</div>
							</MyPageContainer>
						</div>
					)}
				</TabContainer>
			</Wrapper>
		</>
	)
}

export default MyPage
