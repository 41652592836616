import Footer from 'components/Footer'
import Header from 'components/Header'
import React from 'react'

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window
	children: React.ReactElement
}

const InGameLayout = ({ children }: Props) => {
	return (
		<>
			<Header />
			<>{children}</>
		</>
	)
}

export default InGameLayout
