interface UserModel {
	accessToken: string
	refreshToken: string
	tokenType: string
	expiryDuration: number
}

// interface GridColumnModel {
// 	[key: string]: any
// }

/**
 * 로그인
 * @param user
 */
export const setCurrentUser = (user: UserModel) => {
	try {
		if (user) {
			localStorage.setItem(
				`${process.env.REACT_APP_USER_STORAGE_NAME}`,
				JSON.stringify(user)
			)
		} else {
			localStorage.removeItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`)
		}
	} catch (error) {
		console.log(`${process.env.REACT_APP_USER_STORAGE_NAME} ERROR`, error)
	}
}

/**
 * 로그아웃
 */
export const removeCurrentUser = () => {
	localStorage.removeItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`)
}

/**
 * 사용자 정보 호출
 * @returns
 */
export const getCurrentUser = () => {
	let user: any = ''
	try {
		user =
			localStorage.getItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`) != null
				? JSON.parse(
						localStorage.getItem(`${process.env.REACT_APP_USER_STORAGE_NAME}`) || ''
				  )
				: null
	} catch (error) {
		console.log(`${process.env.REACT_APP_USER_STORAGE_NAME} ERROR`, error)
		user = null
	}
	return user
}
