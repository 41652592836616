import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectuserInfo } from 'hooks/userInfo'

/**
 * 페이지 권한 체크
 * @param param0
 * @returns
 */
const RequireAuth = ({ allowedRoles }: any) => {
	const location = useLocation()
	const userInfo = useSelector(selectuserInfo)

	useEffect(() => {
		console.log(userInfo)
	}, [])

	return userInfo?.roles?.find((role: any) =>
		allowedRoles?.includes(role.roleId)
	) ? (
		<Outlet />
	) : userInfo?.roles ? (
		<Navigate
			to="/unauthorized"
			state={{ from: location }}
			replace
		/>
	) : (
		<Navigate
			to="/"
			state={{ from: location }}
			replace
		/>
	)
}

export default RequireAuth
