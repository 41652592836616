import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { ConfirmAlertTypes } from 'types'
import Alert from 'components/Alert'

import BtnHome from 'assets/img/common/btn_home.png'
import BtnBack from 'assets/img/common/btn_back.png'

import Logo from 'assets/img/about/logo.png'

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
`

const BtnHomeLink = styled(Link)`
	position: absolute;
	top: 210px;
	right: 80px;
`

const BtnBackLink = styled.button`
	position: absolute;
	top: 210px;
	left: 80px;
`

const Container = styled.div`
	background: #e86262;
	height: 100vh;
	position: relative;
	.left {
		position: fixed;
		width: 50%;
		left: 0;
		bottom: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right {
		background: #fff;
		position: fixed;
		width: 50%;
		right: 0;
		bottom: 0;
		top: 0;
		padding: 100px;
		padding-top: 150px;
		overflow-y: auto;
	}
`

const About = () => {
	return (
		<Wrapper>
			<Container>
				<div className="left">
					<img
						src={Logo}
						alt="logo"
					/>
				</div>
				<div className="right">
					<h2 className="font-50">
						<b>Lorem ipsum</b>
					</h2>
					<p className="font-25">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Suspendisse in est
						ante in nibh mauris cursus mattis molestie. Ante in nibh mauris cursus
						mattis molestie a iaculis. Viverra mauris in aliquam sem fringilla ut
						morbi tincidunt. Praesent semper feugiat nibh sed pulvinar. Sed arcu non
						odio euismod lacinia. Tincidunt ornare massa eget egestas purus viverra
						accumsan in. Pharetra vel turpis nunc eget lorem dolor sed viverra. Id
						venenatis a condimentum vitae sapien pellentesque habitant morbi
						tristique. Lacus sed turpis tincidunt id. Consectetur a erat nam at lectus
						urna. Rhoncus mattis rhoncus urna neque viverra. Tristique sollicitudin
						nibh sit amet commodo. Dui accumsan sit amet nulla facilisi morbi tempus
						iaculis. Sodales ut etiam sit amet nisl purus in mollis nunc. Nunc
						consequat interdum varius sit amet mattis vulputate enim.
						<br />
						<br />
						Diam maecenas ultricies mi eget mauris pharetra. Mauris pellentesque
						pulvinar pellentesque habitant morbi tristique. Lectus arcu bibendum at
						varius vel. Sit amet consectetur adipiscing elit ut aliquam purus sit
						amet. Odio aenean sed adipiscing diam donec adipiscing tristique risus
						nec. Sed adipiscing diam donec adipiscing tristique. At quis risus sed
						vulputate odio ut enim. Sit amet tellus cras adipiscing enim eu turpis.
						Tristique senectus et netus et malesuada. Eu ultrices vitae auctor eu
						augue ut. Aliquet enim tortor at auctor urna nunc id. Aliquam nulla
						facilisi cras fermentum odio eu feugiat. Tristique nulla aliquet enim
						tortor. Erat imperdiet sed euismod nisi. Etiam erat velit scelerisque in
						dictum. Odio ut enim blandit volutpat maecenas. Pellentesque adipiscing
						commodo elit at.
						<br />
						<br />
						Pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat.
						Amet nisl suscipit adipiscing bibendum est ultricies. Sapien eget mi proin
						sed. Netus et malesuada fames ac turpis egestas maecenas. In ornare quam
						viverra orci sagittis eu volutpat odio. Ornare suspendisse sed nisi lacus
						sed viverra. Lorem ipsum dolor sit amet consectetur adipiscing. Placerat
						orci nulla pellentesque dignissim. Neque volutpat ac tincidunt vitae
						semper. Sodales neque sodales ut etiam sit amet. In egestas erat imperdiet
						sed. Molestie at elementum eu facilisis sed. At augue eget arcu dictum
						varius. Mauris a diam maecenas sed enim ut sem viverra aliquet. Vitae
						justo eget magna fermentum iaculis. At in tellus integer feugiat
						scelerisque varius. Ut enim blandit volutpat maecenas volutpat blandit
						aliquam. Eu sem integer vitae justo eget magna fermentum.
						<br />
						<br />
						Dolor sit amet consectetur adipiscing elit. Bibendum ut tristique et
						egestas quis ipsum suspendisse. Tortor dignissim convallis aenean et
						tortor at risus. Bibendum est ultricies integer quis auctor elit sed.
						Bibendum at varius vel pharetra. Enim eu turpis egestas pretium aenean
						pharetra magna ac. Arcu ac tortor dignissim convallis aenean et tortor at.
						Laoreet sit amet cursus sit amet dictum. Facilisi cras fermentum odio eu
						feugiat. Nec sagittis aliquam malesuada bibendum arcu. Sit amet luctus
						venenatis lectus magna fringilla. Tempor orci dapibus ultrices in iaculis
						nunc. Aenean euismod elementum nisi quis eleifend quam adipiscing. Lacus
						luctus accumsan tortor posuere ac ut consequat.
						<br />
						<br />
						Porta nibh venenatis cras sed felis eget velit aliquet. Duis ultricies
						lacus sed turpis tincidunt id aliquet risus feugiat. Ut sem viverra
						aliquet eget sit. Volutpat diam ut venenatis tellus in metus. Proin libero
						nunc consequat interdum varius sit amet mattis vulputate. Velit laoreet id
						donec ultrices tincidunt. Donec enim diam vulputate ut pharetra sit.
						Semper quis lectus nulla at volutpat diam ut venenatis. Vestibulum lectus
						mauris ultrices eros in. Convallis tellus id interdum velit laoreet id
						donec. Mauris sit amet massa vitae tortor condimentum lacinia quis. Morbi
						tristique senectus et netus. Tortor id aliquet lectus proin nibh nisl
						condimentum id venenatis. Interdum velit euismod in pellentesque massa
						placerat duis.
					</p>
				</div>
			</Container>
		</Wrapper>
	)
}

export default About
