import { useState } from 'react'
import { styled } from 'styled-components'

import { motion } from 'framer-motion'
import FlipNumbers from 'react-flip-numbers'

import HeaderBg from 'assets/img/common/header_bg.png'
import HeaderLogo from 'assets/img/common/header_logo.svg'
import HeaderNick from 'assets/img/common/header_nick.svg'
import HeaderPay from 'assets/img/common/header_pay.svg'
import HeaderAbout from 'assets/img/common/header_about.svg'
import { useNavigate } from 'react-router-dom'

const HeaderWrapper = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	height: 193px;
	background: url(${HeaderBg}) no-repeat 0 0;
`
const HeaderInner = styled.div`
	position: relative;
	width: 57px;
	height: 193px;
`
const HeaderLogoContainer = styled.h1`
	position: absolute;
	top: 50px;
	left: 70px;
`

const Nick = styled.div`
	position: absolute;
	left: 291px;
	top: 50%;
	transform: translateY(-50%);
	width: 223px;
	height: 105px;
	display: block;
	background: url(${HeaderNick}) no-repeat 0 0;
	h2 {
		font-size: 50px;
		color: #fff;
		padding-left: 47px;
		padding-top: 8px;
	}
	p {
		font-size: 30px;
		color: #ce7c8f;
		text-align: right;
		position: absolute;
		bottom: 10px;
		right: 90px;
	}
`
const Pay = styled.button`
	position: absolute;
	left: 491px;
	bottom: 38px;
`

const About = styled.div`
	position: absolute;
	right: 80px;
	top: 17px;
`

const OfficeDream = styled.div`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 18px;
	z-index: 1;
	font-size: 50px;
	background: #00a8ff;
	color: #fff;
	padding: 5px 30px;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: -40px;
		width: 0;
		height: 0;
		border-top: 30px solid transparent;
		border-bottom: 30px solid transparent;
		border-right: 30px solid #00a8ff;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: -40px;
		width: 0;
		height: 0;
		border-top: 30px solid transparent;
		border-bottom: 30px solid transparent;
		border-left: 30px solid #00a8ff;
	}
`

const Header = () => {
	const navigate = useNavigate()
	return (
		<HeaderWrapper>
			<HeaderInner>
				<motion.div
					animate={{ y: [0, -10, 0] }}
					transition={{
						duration: 2,
						times: [0, 0.5, 1],
						repeat: Infinity,
						repeatDelay: 0.5,
					}}
				>
					<HeaderLogoContainer>
						<img
							src={HeaderLogo}
							alt="chat porn"
							className="logo"
						/>
					</HeaderLogoContainer>
				</motion.div>
				<motion.div
					animate={{ opacity: [0, 1] }}
					transition={{ delay: 0.3 }}
				>
					<Nick>
						<h2>Nickname</h2>
						<p className="font-light">
							<FlipNumbers
								height={25}
								width={12}
								color="#ce7c8f"
								background="white"
								play
								perspective={100}
								numbers="12345"
								duration={2}
								delay={0.5}
							/>
						</p>
					</Nick>
					<Pay>
						<img
							src={HeaderPay}
							alt="pay"
						/>
					</Pay>
				</motion.div>
			</HeaderInner>
			<OfficeDream>Office Dream</OfficeDream>
			<motion.div
				animate={{ opacity: [0, 1] }}
				transition={{ delay: 0.3 }}
			>
				<About>
					<button
						type="button"
						onClick={() => navigate('/about')}
					>
						<img
							src={HeaderAbout}
							alt="about"
						/>
					</button>
				</About>
			</motion.div>
		</HeaderWrapper>
	)
}

export default Header
